import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Tag, Drawer, Checkbox, Button } from 'antd';

import { FaHeadset } from "react-icons/fa";
import { FaCalendarDay } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

import './Reset.css';
import Mispichoshelper, { MispichosVars } from "../../utils/Mispichoshelper";
import ReactExport from "react-export-excel";
import CompraReprogramar from "./CompraReprogramar";
//import { Contactar } from "./CompraAcciones";
import UserHappines from "../compra/UserHappines";

// Etiqueta Bonus y Kg Regalo
import tagVariations from '../../functions/tagVariations';
import LoadingOmar from "../OmarComponets/LoadingOmar";
import { VerNotas } from "./CompraAcciones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: MispichosVars.CURRENCY,
});




class TablaPedidos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      drawerInfo: 'omar',
      drawerView: 'default',
      showReprogramar: false,
      orderSelected: {},
      showToolBox: false,
      activeTab: this.props.activeKey,
      loading: true,
      pendientesMPPausa: [],
      pendienteAceptar: [],
      pendientePago: [],
      pendientesMP: [],
      reservas: [],
      aSolucionar: [],
      aceptadoEntregado: [],
      problems_type: [],
      split: [],
      commentsOrderId: 0,
      following: [],
      cant_total_rows: null,
      date: this.props.date ? this.props.date : null,
      params: this.props.params ? this.props.params : null,
      pagination: 20,
      check: false,
    };
  }


  //Drawer ------------------


  onCloseDrawer = () => {
    this.setState({
      orderSelected: {}, drawerView: 'default',
    });
  };

  callback = (key) => {
    console.log(key);
  }

  //-------------------------


  componentDidMount() {
    this.cargarTabla()
  }

  componentWillReceiveProps(next_props) {

    // Cambio de Tab
    if (next_props.activeKey !== this.props.activeKey) {
      this.setState({ activeTab: next_props.activeKey }, () => {
        // Cambio de activeKey
        this.cargarTabla()
      });
    }

    // Cambio de fecha
    if (next_props.date !== this.props.date) {
      this.setState({ date: next_props.date }, () => {
        this.cargarTabla()
      });
    }

    // Cambio de parametros
    if (next_props.params !== this.props.params) {
      this.setState({ params: next_props.params }, () => {
        this.cargarTabla()
      });
    }

  }

  cargarTabla() {
    this.setState({ loading: true });
    console.log('carga tablas');
    this.state.activeTab === 1 && this.buscaPedidos('aSolucionar', this.guardaASolucionar);
    this.state.activeTab === 2 && this.buscaPedidos('pendientesMP', this.guardaPendienteMP);
    this.state.activeTab === 3 && this.buscaPedidos('pendienteAceptar', this.guardaPendienteAceptar);
    this.state.activeTab === 4 && this.buscaPedidos('pendientePago', this.guardaPendientePago);
    this.state.activeTab === 5 && this.buscaPedidos('aceptadoEntregado', this.guardaProcesando);
    this.state.activeTab === 6 && this.buscaPedidos('split', this.guardaSplit);
    this.state.activeTab === 7 && this.buscaPedidos('following', this.guardaFollowing);
    this.state.activeTab === 8 && this.buscaPedidos('oportunidades', this.guardaOportunidades);
    this.state.activeTab === 9 && this.buscaPedidos('pendientesMPPausa', this.guardaPendienteMPPausa);
    this.state.activeTab === 11 && this.buscaPedidos('reservas', this.guardaReservas);
  }

  setCompraSelected = (record) => {
    this.props.history.push('/compras?compra_id=' + record.id);
  }

  buscaPedidos(estadoCompra, safe, pageNumber = 1) {

    console.log("params!!!", this.state.params);
    let params = this.state.params ? this.state.params : {};
    const limit = this.state.pagination;

    params.date = this.state.date ? this.state.date : null;
    params.estadoCompra = estadoCompra;
    params.page = pageNumber;
    params.limit = limit;

    Mispichoshelper.getCompras(params).then(safe);
  }

  // TABLA PEDIDOS
  guardaPendienteAceptar = (data) => {
    this.setState({ pendienteAceptar: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaPendientePago = (data) => {
    this.setState({ pendientePago: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaASolucionar = (data) => {
    this.setState({ aSolucionar: data.payload.rows, problems_type: data.payload.problems_type, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaProcesando = (data) => {
    this.setState({ aceptadoEntregado: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaPendienteMP = (data) => {
    this.setState({ pendientesMP: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaOportunidades = (data) => {
    this.setState({ oportunidades: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaSplit = (data) => {
    this.setState({ split: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaFollowing = (data) => {
    this.setState({ following: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaPendienteMPPausa = (data) => {
    this.setState({ pendientesMPPausa: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }
  guardaReservas = (data) => {
    this.setState({ reservas: data.payload.rows, cant_total_rows: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }

  changePage = (title, numberPage) => {

    switch (title) {
      case 'A solucionar':
        this.buscaPedidos('aSolucionar', this.guardaASolucionar, numberPage);
        break;
      case 'Estancados':
        this.buscaPedidos('pendientesMP', this.guardaPendienteMP, numberPage);
        break;
      case 'Reservas':
        this.buscaPedidos('reservas', this.guardaReservas, numberPage);
        break;
      case 'Oportunidades':
        this.buscaPedidos('oportunidades', this.guardaOportunidades, numberPage);
        break;
      case 'Pausados':
        this.buscaPedidos('pendientesMPPausa', this.guardaPendienteMPPausa, numberPage);
        break;
      case 'Pendientes de aceptar':
        this.buscaPedidos('pendienteAceptar', this.guardaPendienteAceptar, numberPage);
        break;
      case 'Pendientes de pago':
        this.buscaPedidos('pendientePago', this.guardaPendientePago, numberPage);
        break;
      case 'Aceptados a entregar':
        this.buscaPedidos('aceptadoEntregado', this.guardaProcesando, numberPage);
        break;
      case 'Split':
        this.buscaPedidos('split', this.guardaSplit, numberPage);
        break;
      case 'Seguimiento':
        this.buscaPedidos('following', this.guardaFollowing, numberPage);
        break;
      default: return null
    }

  }

  onChangeBox = (e) => {
    if (e.target.checked) {//esta en true
      this.setState({
        check: true,
        pagination: 3000
      }, () => this.cargarTabla())
    } else {
      this.setState({
        check: false,
        pagination: 20
      }, () => this.cargarTabla())
    }
  }
  downloadToExcel = (cols, orders) => {
    if (this.state.downloadExcel) {
      return (
        <div>
          <label>
            <Checkbox checked={this.state.check} onChange={this.onChangeBox}>Descargar todos los resultados (hasta 3.000 por página)</Checkbox>
          </label>
          <Download cols={cols} orders={orders} />
        </div>
      )
    }
    return (
      <div className='DownloadExcel' onClick={() => this.setState({ downloadExcel: true })}>
        <Button ghost="1">Descargar en Excel</Button>
      </div>
    )
  }

  columnsProblema = () => {
    console.log('problems ', this.state.problems_type);
    return (
      [
        {
          title: '#Pedido',
          dataIndex: 'id',
          key: 'id',
          defaultSortOrder: 'descend',
          width: 150,

          render: (compra, row, index) => {
            var inconveniente = '';
            var capacidad = '';
            var autoacept = '';

            if (row.tag_seguimiento !== undefined && row.tag_seguimiento > 0) {
              inconveniente = <><Tag alt="Ver notas" title="Ver notas" onClick={() => this.setState({ commentsOrderId: row.id })} style={{ cursor: 'pointer' }} color='purple'>Seguimiento</Tag></>
            }
            if (row.tag_capacidad > 0) {
              capacidad = <Tag color='gold'>Capacidad</Tag>
            }
            if (row.tag_auto_aceptada > 0) {
              autoacept = <Tag color='orange'>Autoaceptada</Tag>
            }
            return <div>{compra} <br /> {autoacept} {inconveniente} {capacidad}</div>
          },
          fixed: 'left',
        },
        {
          title: 'fecha de entrega',
          dataIndex: 'fechaEntrega',
          key: 'fechaEntrega',
          render: (compra, row, index) => {
            var reprogramado = '';
            if (row.tag_reprogramado > 0) {
              reprogramado = <Tag color='red'>Reprogramado</Tag>
            }
            return <div>{compra} <br /> {reprogramado} </div>
          },
        },
        {
          title: 'Estado',
          dataIndex: 'estado',
          key: 'estado',
          defaultSortOrder: 'descend',
          render: (estado, row, index) => {
            var deliveryData = '';
            if (row.estado_transporte && row.estado_transporte === "en camino") {
              deliveryData = <Tag color='blue'>En camino</Tag>
            } else if (row.estado_transporte && row.estado_transporte.length > 0) {
              deliveryData = <Tag color='green'>{row.estado_transporte}</Tag>
            }
            return <div>{estado} <br /> {deliveryData} </div>
          },
        },
        {
          title: 'Tipo problema',
          dataIndex: 'tipo_problema',
          key: 'tipo_problema',
          filters: this.state.problems_type != undefined ? this.state.problems_type : [],
          filterMultiple: false,
          onFilter: (value, record) => {
            return record.tipo_problema.indexOf(value) === 0
          },
        },
        {
          title: 'Petshop comentario',
          dataIndex: 'petshop_comentarios',
          key: 'petshop_comentarios',
        },

        {
          title: 'Cliente',
          dataIndex: 'cliente',
          key: 'cliente',
          //...this.getColumnSearchProps('cliente'),
          defaultSortOrder: 'descend',
        },
        {
          title: 'Domicilio',
          dataIndex: 'domicilio',
          key: 'domicilio',
          //...this.getColumnSearchProps('domicilio'),
          render: (compra, row, index) => {
            var domicilio = '';
            if (row.tag_cambio_domicilio > 0) {
              domicilio = <Tag color='Tomato'>Cambio domicilio</Tag>
            }
            return <div>{compra} <br />  {domicilio} </div>
          },
        },
        {
          title: 'Petshop',
          dataIndex: 'proveedor',
          key: 'proveedor',
          //...this.getColumnSearchProps('proveedor'),
          defaultSortOrder: 'descend',
        },
        {
          title: 'Producto',
          dataIndex: 'producto',
          key: 'producto',
          width: 400,
          //...this.getColumnSearchProps('producto'),
          defaultSortOrder: 'descend',
          render: (producto, row, index) => {
            var array_producto = producto.split(',');
            return <ul className="listaTablaPedidoItem">{array_producto.map((cada_producto, index) => <li key={index} dangerouslySetInnerHTML={{ __html: tagVariations(cada_producto) }}></li>)}</ul>
          },
        },
        {
          title: 'Monto',
          dataIndex: 'monto',
          key: 'monto',
          defaultSortOrder: 'descend',
          render: (text, row, index) => {
            let total = text
            if (row.discount) {
              total = total - row.discount;
            }
            return <span>{formatter.format(total)}</span>
          },
        },
        {
          title: 'Pago',
          dataIndex: 'medio',
          key: 'medio',
          defaultSortOrder: 'descend',
        },
        {
          title: 'Fecha creación',
          dataIndex: 'fechaCreacion',
          key: 'fechaCreacion',
          //...this.getColumnSearchProps('fecha'),
          defaultSortOrder: 'descend',
        },
        {
          title: 'Nota',
          dataIndex: 'nota',
          key: 'nota',
        },

      ]
    )
  }

  closeDrawerNotes = () => {
    this.setState({ commentsOrderId: 0 })
  }

  columns = () => {
    return [
      {
        title: '#Pedido',
        dataIndex: 'id',
        key: 'id',
        defaultSortOrder: 'descend',
        width: 140,

        render: (compra, row) => {
          var inconveniente = '', capacidad = '', autoacept = '', problema = '', manual = '', contacto = '', seguimiento = '';
          if (row.tag_mala_experiencia > 0) {
            inconveniente = <Tag color='green'>Rendimiento</Tag>
          }
          if (row.tag_capacidad > 0) {
            capacidad = <Tag color='gold'>Capacidad</Tag>
          }
          if (row.tag_seguimiento !== undefined && row.tag_seguimiento > 0) {
            seguimiento = <><Tag alt="Ver notas" title="Ver notas" onClick={() => this.setState({ commentsOrderId: row.id })} style={{ cursor: 'pointer' }} color='purple'>Seguimiento</Tag></>
          }
          if (row.tag_auto_aceptada) {
            autoacept = <Tag color='orange'>Autoaceptada</Tag>
          }
          if (row.tipo_problema) {
            problema = <Tag color='red'>Problemas</Tag>
          }
          if (row.tag_contacto === "contactado") {
            contacto = <Tag color='green'>Contactado</Tag>
          }

          if (row.treggo_id) {
            manual = <Tag backColor="withe" color={`${row.treggo_id == 'on demand' ? 'violet' : 'green'}`}>Envíos "{row.treggo_id}"</Tag>
          }

          if (row.tag_sospechosa) {
            manual = <Tag backColor="withe" color='red'>Sospechosa</Tag>
          }

          return <div className='ItemTablaFlex'>{compra} <br /> {problema}  {autoacept} {contacto} {inconveniente} {capacidad} {seguimiento} {manual}</div>
        },
        fixed: 'left',
      },
      {
        title: 'fecha de entrega',
        dataIndex: 'fechaEntrega',
        key: 'fechaEntrega',
        width: 200,
        render: (compra, row, index) => {
          var reprogramado = '';
          if (row.tag_reprogramado > 0) {
            reprogramado = <Tag color='red'>Reprogramado</Tag>
          }
          var lega_tarde = '';
          if (row.tag_llega_tarde > 0) {
            lega_tarde = <Tag color='red'>{row.tag_llega_tarde_mensaje}</Tag>
          }
          return <div>{compra}<br />{reprogramado}<br />{lega_tarde}</div>
        },
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        defaultSortOrder: 'descend',
        width: 100,
        render: (estado, row, index) => {
          var deliveryData = '';
          if (row.estado_transporte && row.estado_transporte === "en camino") {

            deliveryData = <Tag color='blue'>En camino</Tag>
          } else if (row.estado_transporte && row.estado_transporte.length > 0) {
            deliveryData = <Tag color='green'>{row.estado_transporte}</Tag>
          }
          return <div>{estado} <br /> {deliveryData} </div>
        },
      },
      {
        title: 'Cliente',
        dataIndex: 'cliente',
        key: 'cliente',
        //...this.getColumnSearchProps('cliente'),
        defaultSortOrder: 'descend',
        render: (cliente, row) => {
          return (
            <div>
              <span style={{ display: 'block' }}>Cliente:{cliente}</span>
              <span><strong>Mascota:</strong> {row.mascotas ? row.mascotas : 'sin informar'}</span>
            </div>
          )
        },
      },
      {
        title: 'Domicilio',
        dataIndex: 'domicilio',
        key: 'domicilio',
        //...this.getColumnSearchProps('domicilio'),
        render: (compra, row, index) => {
          var domicilio = '';
          if (row.tag_cambio_domicilio > 0) {
            domicilio = <Tag color='red'>Cambio domicilio</Tag>
          }
          return <div>{compra} <br />  {domicilio} </div>
        },
      },
      {
        title: 'Petshop',
        dataIndex: 'proveedor',
        key: 'proveedor',
        //...this.getColumnSearchProps('proveedor'),
        defaultSortOrder: 'descend',
        width: 140,
      },
      {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
        //...this.getColumnSearchProps('producto'),
        width: 300,
        defaultSortOrder: 'descend',
        render: (producto, row, index) => {
          var array_producto = producto.split(',');
          return <ul className="listaTablaPedidoItem">{array_producto.map((cada_producto, index) =>
            <li key={index} dangerouslySetInnerHTML={{ __html: tagVariations(cada_producto) }}></li>
          )}
          </ul>
        },
      },
      {
        title: 'Monto',
        dataIndex: 'monto',
        key: 'monto',
        defaultSortOrder: 'descend',
        render: (text, row, index) => {
          let total = text
          if (row.discount) {
            total = total - row.discount;
          }
          return <span>{formatter.format(total)}</span>
        },
      },
      {
        title: 'Pago',
        dataIndex: 'medio',
        key: 'medio',
        defaultSortOrder: 'descend',
      },
      {
        title: 'Fecha creación',
        dataIndex: 'fechaCreacion',
        key: 'fechaCreacion',
        //...this.getColumnSearchProps('fecha'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Nota',
        dataIndex: 'nota',
        key: 'nota',
      },

    ];
  }

  table = (cant_pedidos, pedidos, title, key, icon, background) => {
    var columnsToUse = title !== 'A solucionar' ? this.columns() : this.columnsProblema();
    let headerClass = background ? 'TableBoxHeader ' + background : 'TableBoxHeader';

    // console.log('canti pedidos', cant_pedidos, 'pedidos', pedidos, 'title', title);
    return (
      <article className={this.props.activeKey === key ? "ShowElement" : ''} >
        {this.state.commentsOrderId > 0 ? <VerNotas closeDrawer={this.closeDrawerNotes} orderId={this.state.commentsOrderId} /> : null}

        <div className={headerClass}>
          <span>{title + " " + this.state.cant_total_rows}</span>
          {this.downloadToExcel(columnsToUse, pedidos)}
        </div>
        <div className='TableBody'>
          <Table
            onRow={(record, rowIndex) => {
              return {
                ///onClick: event => { this.setState({ orderSelected: record }); },
                onDoubleClick: (event) => { this.props.history.push('/compras?compra_id=' + record.id); }
              };
            }}
            rowSelection={this.props.rowSelection}
            columns={columnsToUse}
            dataSource={pedidos}
            pagination={
              {
                position: 'bottom',
                pageSize: this.state.pagination,
                total: this.state.cant_total_rows,
                onChange: (pageNumber) => this.changePage(title, pageNumber)
              }
            }
            scroll={{ x: 2500 }}
            bordered
            size={'middle'}
          />
        </div>
      </article>
    );
  }

  openInZoho = (email) => {
    window.open('https://crmplus.zoho.com/mispichos/index.do/cxapp/support/mispichos/ShowHomePage.do#Contacts/search/CurDep/' + email);
  }

  whatsappMessage = (phone) => {
    window.open('https://api.whatsapp.com/send?phone=' + phone);
  }

  drawerContet = () => {
    console.log('order selected', this.state.orderSelected)
    return (

      <Drawer
        title="Shortcut by Omi"
        width={400}  // ---------------------> Aca se puede controlar el ancho
        onClose={this.onCloseDrawer}
        visible={this.state.orderSelected.id !== undefined}
      >
        {this.state.drawerView === 'default' ?
          <div className='DrawerShortcut'>
            <h4>
              Pedido #{this.state.orderSelected.id}
            </h4>
            <UserHappines badExperienceLastOrder={true} goodExperiences={11} badExperiences={1} cliente={'cliente'} />
            <ol className='DrawerDashboardList'>
              <li>
                <h6>{this.state.orderSelected.cliente}</h6>
                <div className='RowCenter'>
                  <b>{this.state.orderSelected.cliente_telefono}</b>
                  <div>
                    <button onClick={() => { this.openInZoho(this.state.orderSelected.cliente_email) }} >
                      <FaHeadset />
                    </button>
                    <button onClick={() => { this.whatsappMessage(this.state.orderSelected.cliente_telefono) }} >
                      <FaEnvelope />
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <h6>{this.state.orderSelected.proveedor}</h6>
                <div className='RowCenter'>
                  <b>{this.state.orderSelected.petshop_telefono}</b>
                  <div>
                    <button onClick={() => { this.openInZoho(this.state.orderSelected.petshop_email) }} >
                      <FaHeadset />
                    </button>
                    <button onClick={() => { this.whatsappMessage(this.state.orderSelected.petshop_telefono) }} >
                      <FaEnvelope />
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <h6>Entrega el {this.state.orderSelected.fechaEntrega}</h6>
                <div className='RowCenter'>
                  <b>¿Reprogramar?</b>
                  <button onClick={() => { this.setState({ showReprogramar: true }) }} >
                    <FaCalendarDay />
                  </button>
                </div>
              </li>
              {this.state.orderSelected.tag_seguimiento ?
                <li>
                  <h6>En Seguimiento</h6>
                  <div className='RowCenter'>
                    <b>Hay {this.state.orderSelected.tag_seguimiento} nota/s</b>
                    <button onClick={() => { this.setState({ drawerView: 'Seguimiento' }) }} >
                      <FaComments />
                    </button>
                  </div>
                </li>
                : null}
            </ol>
            <button className='CustomOmiButton' onClick={() => { this.props.history.push('/compras?compra_id=' + this.state.orderSelected.id); }}>Ver el pedido</button>
          </div>
          :
          <div>
            <button className='CustomOmiButton' onClick={() => { this.setState({ drawerView: 'default' }) }}>
              Regresar
            </button>
          </div>
        }
      </Drawer>
    )
  }


  refrescarCompra = () => {
    this.hideReprogramar();
  }
  hideReprogramar = () => {
    this.setState({ orderSelected: {}, showReprogramar: false }, () => this.props.reset())
  }



  reprogramarContent = () => {
    return <CompraReprogramar
      refrescarCompra={this.refrescarCompra}
      showReprogramar={this.state.showReprogramar}
      hideReprogramar={this.hideReprogramar}
      orderId={this.state.orderSelected.id}
      compra={this.state.orderSelected} />
  }


  render() {
    const { loading } = this.state;

    // Loading
    if (loading) {
      return (
        <LoadingOmar />
      );
    }

    return (
      <section className='TableBox'>

        {this.drawerContet()}
        {this.state.orderSelected.id !== undefined && this.reprogramarContent()}
        {this.state.activeTab === 1 && this.table(this.props.panel.aSolucionar, this.state.aSolucionar, 'A solucionar', 1, 'alert', 'rojo')}
        {this.state.activeTab === 2 && this.table(this.props.panel.pendientesMP, this.state.pendientesMP, 'Estancados', 2, 'fire', 'naranja')}
        {this.state.activeTab === 11 && this.table(this.props.panel.reservas, this.state.reservas, 'Reservas', 11, 'fire', 'naranja')}
        {this.state.activeTab === 3 && this.table(this.props.panel.pendienteAceptar, this.state.pendienteAceptar, 'Pendientes de aceptar', 3, 'clock-circle', 'amarillo')}
        {this.state.activeTab === 4 && this.table(this.props.panel.pendientePago, this.state.pendientePago, 'Pendientes de pago', 4, 'hourglass', 'gris')}
        {this.state.activeTab === 5 && this.table(this.props.panel.aceptadoEntregado, this.state.aceptadoEntregado, 'Aceptados a entregar', 5, 'reconciliation', 'azul')}
        {this.state.activeTab === 6 && this.table(this.props.panel.split, this.state.split, 'Split', 6, 'apartment', 'rosado')}
        {this.state.activeTab === 7 && this.table(this.props.panel.following, this.state.following, 'Seguimiento', 7, 'search', 'purpura')}
        {this.state.activeTab === 8 && this.table(this.props.panel.oportunidades, this.state.oportunidades, 'Oportunidades', 8, 'search', 'verde')}
        {this.state.activeTab === 9 && this.table(this.props.panel.pendientesMPPausa, this.state.pendientesMPPausa, 'Pausados', 9, 'pause', 'blanco')}
      </section>
    )
  }
}


class Download extends React.Component {
  render() {

    return (
      <div className='botonParaDescargarExcel'>
        <ExcelFile>
          <ExcelSheet data={this.props.orders} name="Pedidos">
            {this.props.cols.map((eachCol, iCol) =>
              <ExcelColumn key={iCol} label={eachCol.title} value={eachCol.key} />
            )}
            {/*
            <ExcelColumn label={'ciudad'} value={'dom_ciudad'} />
            <ExcelColumn label={'localidad'} value={'dom_localidad'} />
            <ExcelColumn label={'barrio'} value={'dom_barrio'} />
            */}
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  }
}

export default withRouter(TablaPedidos);
export { Download };

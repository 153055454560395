import AuthService from "./AuthService";
import moment from "moment";
const Auth = new AuthService();

const Mispichoshelper = {
  openLink: function (link) {
    window.open(Auth.createAuthLink(link));
  },
  playSounds: function ({
    order,
    type,
    playerPendiente,
    playerOportunidad,
    playerDemorado,
    endSchedule,
  }) {
    var playPromise = false;
    if (order.estado === "procesando") {
      playPromise = playerPendiente.play();
    } else if (type === "pedidoSorteo") {
      playPromise = playerOportunidad.play();
    } else if (order.estado === "preparando" && endSchedule) {
      playPromise = playerDemorado.play();
    } else {
      playPromise = playerOportunidad.play();
    }

    if (playPromise && playPromise !== undefined) {
      playPromise
        .then((_) => {
          playerPendiente.onended = () => {
            playerPendiente.pause();
            playerPendiente["currentTime"] = 0;
          };
          playerOportunidad.onended = () => {
            playerOportunidad.pause();
            playerOportunidad["currentTime"] = 0;
          };
          playerDemorado.onended = () => {
            playerDemorado.pause();
            playerDemorado["currentTime"] = 0;
          };
          var showAudioPermission = false;
          var params = {
            playerPendiente: playerPendiente,
            playerOportunidad: playerOportunidad,
            playerDemorado: playerDemorado,
            showAudioPermission: showAudioPermission,
          };
          return params;
        })
        .catch((error) => {
          console.log("sin permisos para habilitar audio ", error);
          var showAudioPermission = true;
          return showAudioPermission;
        });
    }
  },
  validateDate: function (order) {
    //let now = moment('2020-08-23 10:00:00');
    let response = {};
    let now = moment();
    let fechaIni = moment(
      order.fecha_pura +
      " " +
      (order.horaInicio > 9 ? order.horaInicio : "0" + order.horaInicio) +
      ":00:00"
    );
    let fechaFin = moment(
      order.fecha_pura +
      " " +
      (order.horaFin > 9 ? order.horaFin : "0" + order.horaFin) +
      ":00:00"
    );
    //console.log("NOW -------> ", now.format("YYYY-MM-DD h:mm:ss a"));
    //console.log("INI -------> ", fechaIni.format("YYYY-MM-DD h:mm:ss a"));
    //console.log("FIN -------> ", fechaFin.format("YYYY-MM-DD h:mm:ss a"));

    // Dia
    if (
      moment(now.format("YYYY-MM-DD")).isSame(fechaIni.format("YYYY-MM-DD"))
    ) {
      //console.log("DIA -------> SI");
      response.day = true;
    } else {
      response.day = false;
    }

    // Franja horaria
    if (now.isBefore(fechaIni)) {
      console.log("DIA -------> NO");
      response.time = "antes";
    }

    if (now.isSameOrAfter(fechaIni) && now.isSameOrBefore(fechaFin)) {
      console.log("LOGICA -------> DURANTE");
      response.time = "durante";
    }

    if (now.isAfter(fechaFin)) {
      console.log("LOGICA -------> DESPUES");
      response.time = "despues";
    }
    return response;
  },
  addMinutes: function (date, minutes) { return new Date(date.getTime() + minutes * 60000); },
  restMinutes: function (date, minutes) { return new Date(date.getTime() - minutes * 60000); },
  ClientAPI: {
    getBrandsHome: function (params) { return Auth.getPublic(CLIENT_HOME_BRANDS_GET, params); },
  },
  Marketing: {
    getLandings: function (params) { return Auth.get(MARKETING_GET_LANDINGS, params); },
    getLanding: function (params) { return Auth.get(MARKETING_GET_LANDING, params); },
    editLanding: function (params) { return Auth.post(MARKETING_EDIT_LANDING, params); },
    newLanding: function (params) { return Auth.post(MARKETING_NEW_LANDING, params); },
  },
  Accountability: {
    markOrderAsSuspicius: function (params) { return Auth.post(ACCOUNTABILITY_POST_ORDER_SUSPICIUS, params); },
    getClientInfoPayment: function (params) { return Auth.get(ACCOUNTABILITY_GET_CLIENT_PAYMENT, params); },
    getDashboard: function (params) { return Auth.get(ACCOUNTABILITY_GET_DASHBOARD, params); },
  },
  Petshop: {
    getXubioClientById: function (params) { return Auth.get(PETSHOP_GET_XUBIO_CLIENTS, params); },
    getNotificationHistorial: function (params) { return Auth.get(PETSHOP_NOTIFICATION_HISTORIAL_GET, params); },
    getReportStatics: function (params) { return Auth.get(PETSHOP_GET_REPORT_STATICS, params); },
    getDashboard: function (params) { return Auth.get(PETSHOP_GET_DASHBOARD, params); },
    getOrders: function (params) { return Auth.get(PETSHOP_GET_ORDERS, params); },
    getOrderById: function (params) { return Auth.get(PETSHOP_GET_ORDER_BY_ID, params); },
    getNews: function (params) { return Auth.get(PETSHOP_GET_NEWS, params); },
    getConsultaEntrega: function (params) { return Auth.get(PETSHOP_COMPRA_CONSULTA_ENTREGA_GET, params); },
    getConsultaStock: function (params) { return Auth.get(PETSHOP_COMPRA_CONSULTA_STOCK_GET, params); },
    getAskPhone: function (params) { return Auth.get(PETSHOP_CONSULTA_TELEFONO_GET, params); },
    getAccountRegistry: function (params) { return Auth.get(PETSHOP_ACCOUNT_REGISTRY_GET, params); },
    getPayingWeekly: function (params) { return Auth.get(PETSHOP_PAYING_WEEKLY_GET, params); },
    getPayingMonthly: function (params) { return Auth.get(PETSHOP_PAYING_MONTHLY_GET, params); },
    getSpecialDays: function (params) { return Auth.get(PETSHOP_SPECIAL_DAYS_GET, params); },
    getPolygon: function (params) { return Auth.get(PETSHOP_POLIGONO_GET, params); },
    getProducts: function (params) { return Auth.get(PETSHOP_PRODUCTOS_GET, params); },
    getSchedule: function (params) { return Auth.get(PETSHOP_SCHEDULE_GET, params); },
    getProflie: function (params) { return Auth.get(PETSHOP_PROFILE_GET, params); },
    getReporteLiquidacion: function (params) { return Auth.getLink(REPORTE_LIQUIDACION_GET, params); },
    getReporteComisiones: function (params) { return Auth.getLink(REPORTE_COMISIONES_GET, params); },
    getReporteDescuentos: function (params) { return Auth.getLink(REPORTE_DESCUENTOS_GET, params); },
    postAddress: function (params) { return Auth.post(PETSHOP_ADDRESS_POST, params); },
    postProfile: function (params) { return Auth.post(PETSHOP_PROFILE_POST, params); },
    postSchedule: function (params) { return Auth.post(PETSHOP_SCHEDULE_POST, params); },
    postProducts: function (params) { return Auth.post(PETSHOP_PRODUCTOS_POST, params); },
    potSendInvoiceByEmail: function (params) { return Auth.post(PETSHOP_INVOICE_EMAIL_POST, params); },
    postSpecialDays: function (params) { return Auth.post(PETSHOP_SPECIAL_DAYS_POST, params); },
    postConsultaStock: function (params) { return Auth.post(PETSHOP_COMPRA_CONSULTA_STOCK_POST, params); },
    postConsultaEntrega: function (params) { return Auth.post(PETSHOP_COMPRA_CONSULTA_ENTREGA_POST, params); },
    postOrderDelegate: function (params) { return Auth.post(PETSHOP_COMPRA_DELEGAR_POST, params); },
    postOrderPreparando: function (params) { return Auth.post(PETSHOP_COMPRA_ACEPTAR_POST, params); },
    postOrderProblem: function (params) { return Auth.post(PETSHOP_COMPRA_PROBLEMA_POST, params); },
    postOrderComplete: function (params) { return Auth.post(PETSHOP_COMPRA_ENTREGADO_POST, params); },
    postSendPolygon: function (params) { return Auth.post(PETSHOP_POLIGONO_POST, params); },
    postInformNotificationViewed: function (params) { return Auth.post(PETSHOP_NOTIFICATION_VIEWED_POST, params); },
    postSurveyResponse: function (params) { return Auth.post(PETSHOP_NOTIFICATION_SURVEY_VIEWED_POST, params); },
    alertConsoleOpen: function (params) { return Auth.post(PETSHOP_CONSOLE_OPEN_POST, params); },
    postDelegatePreparingOrder: function (params) { return Auth.post(PETSHOP_DELEGATE_PREPARING_ORDER_POST, params); },
    validateOrder: function (params) { return Auth.post(PETSHOP_COMPRA_VALIDATE_POST, params); },
    getMegaBuscador: function (params) { return Auth.get(PETSHOP_MEGA_BUSCADOR_GET, params); },
    getTransporters: function (params) { return Auth.get(GET_TRANSPORTERS, params); },
    postTransporters: function (params) { return Auth.post(POST_TRANSPORTERS, params); },
    postUpdateShosLabelMoova: function (params) { return Auth.post(UPDATE_SHOW_LABEL_MOOVA, params); },
    getReportSoldProducts: function (params) { return Auth.get(PETSHOP_GET_REPORT_SOLD_PRODUCTS, params); },
    getProductsCategory: function (params) { return Auth.get(PETSHOP_PRODUCTS_CATEGORY, params); },
    getProductsCategoryByBrands: function (params) { return Auth.get(PETSHOP_PRODUCTS_CATEGORY_BRANDS, params); },
    autoAceptOrdersPost: function (params) { return Auth.post(PETSHOP_AUTO_ACEPT_ORDERS, params) },
    getTransportersManualAssignation: function (params) { return Auth.get(PETSHOP_TRANSPORTERS_MANUAL_ASSIGNATION, params); },
    postOrdersToTransporter: function (params) { return Auth.post(PETSHOP_TRANSPORTERS_ASSIGN_ORDERS, params); },
    postRemoveOrdersFromTransporter: function (params) { return Auth.post(PETSHOP_TRANSPORTERS_REMOVE_ASSIGNED_ORDERS, params); },
    getSchedulesActive: function (params) { return Auth.get(PETSHOP_SCHEDULES_ACTIVES, params); },
    postPickUpOrders: function (params) { return Auth.post(PICK_UP_ORDERS, params); },
    getNotes: function (params) { return Auth.get(PETSHOP_GET_NOTES, params); },
    getClientPayment: function (params) { return Auth.get(PETSHOP_GET_CLIENT_PAYMENT, params); },
    getReturnOrders: function (params) { return Auth.get(PETSHOP_GET_RETURN_ORDERS, params) },
    resolveReturnOrder: function (params) { return Auth.post(PETSHOP_POST_RESOLVE_RETURN_ORDERS, params) }
  },
  AccountManager: {
    getInvoices: function (params) { return Auth.get(ACCOUNT_MANAGER_INVOICES_GET, params); },
    getPetshopList: function (params) { return Auth.get(ACCOUNT_MANAGER_PETSHOPLIST_GET, params); },
    getTransfers: function (params) { return Auth.get(ACCOUNT_MANAGER_TRANSFERS_GET, params); },
    getTxtBBVA: function (params) { return Auth.get(ACCOUNT_MANAGER_BBVA_TXT_GET, params); },
    getXubioClients: function (params) { return Auth.get(ACCOUNT_GET_XUBIO_CLIENTS, params); },
    getPetshopProfile: function (params) { return Auth.get(ACCOUNT_GET_PETSHOP_PROFILE_GET, params); },
    postBonificaPetshop: function (params) { return Auth.post(ACCOUNT_MANAGER_ORDER_NO_COMISSION_POST, params); },
    postTransferMoney: function (params) { return Auth.post(ACCOUNT_MANAGER_TRANSFER_MONEY_POST, params); },
    postTransferMoneyPrisma: function (params) { return Auth.post(ACCOUNT_MANAGER_TRANSFER_MONEY_PRISMA_POST, params); },
    postAllowChangeData: function (params) { return Auth.post(ACCOUNT_ALLOW_CHANGE_DATA_POST, params); },
    postCashRow: function (params) { return Auth.post(ACCOUNT_CASH_ROW_POST, params); },
    invoiceCreate: function (params) { return Auth.post(ACCOUNT_INVOICE_CREATE, params); },
    postPausePetshop: function (params) { return Auth.post(ACCOUNT_PAUSE_POST, params); },
    postInactivePetshop: function (params) { return Auth.post(ACCOUNT_INACTIVE_POST, params); },
    postChangeDateToCalculateIndexMp: function (params) { return Auth.post(ACCOUNT_CHANGE_DATE_CALCULATE_INDX_POST, params); },
    sendPetCategory: function (params) { return Auth.post(SEND_PET_CATEGORY, params); },
    activeAutoAcept: function (params) { return Auth.post(SEND_ACTIVE_AUTO_ACEPT, params); },
  },
  ControlLogistic: {
    getDashboardInformation: function (params) { return Auth.get(CONTROL_LOGISTIC_DASHBOARD, params); },
  },
  Commercial: {
    getLandings: function (params) { return Auth.get(LANDING_GET, params); },
    createLanding: function (params) { return Auth.post(LANDING_CREATE_POST, params); },
    updateLanding: function (params) { return Auth.post(LANDING_UPDATE_POST, params); },
    activeLanding: function (params) { return Auth.post(LANDING_ACTIVE_POST, params); },
    inactiveLanding: function (params) { return Auth.post(LANDING_INACTIVE_POST, params); },
    productsVariations: function (params) { return Auth.get(PRODUCTS_VARIATIONS_GET, params); },
    sendPriceClevertap: function (params) { return Auth.post(PRODUCTS_CLEVERTAP_CHANGE_PRICE, params); },

  },
  Transporter: {
    getOrders: function (params) { return Auth.get(TRANSPORTER_GET_ORDERS, params); },
    sendStartDelivery: function (params) { return Auth.post(TRANSPORTER_START_DELIVERY_POST, params); },
    sendCompleteDelivery: function (params) { return Auth.post(TRANSPORTER_COMPLETE_DELIVERY_POST, params); },
  },
  UserChekingAccount: {
    getMoveList: function (params) { return Auth.get(REFERRED_GET_MOVE_LIST, params); },
    getTotalBalance: function (params) { return Auth.get(REFERRED_GET_ALL_BALANCE, params); },
    getReferredList: function (params) { return Auth.get(REFERRED_GET_LIST, params); },

  },
  getMisPichosSuspicious: function (params) { return Auth.get(ORDERS_SUSPICIUS, params); },
  getConsultaEntrega: function (params) { return Auth.get(CONSULTA_ENTREGA, params); },
  getConsultaStock: function (params) { return Auth.get(CONSULTA_STOCK, params); },
  getEmails: function (params) { return Auth.get(REPORT_MAIL_GET, params); },
  getConsultaRadio: function (params) { return Auth.get(CONSULTA_RADIO, params); },
  getHorariosDeReprogramacion: function (params) { return Auth.get(REPROGRAMACION_GET_HORARIOS, params); },
  getCompra: function (compraId) { return Auth.get(COMPRA_GET_ONE + "?compra_id=" + compraId); },
  getCompras: function (params = false) { return Auth.get(COMPRA_GET_ALL, params); },
  getOrdersFixer: function (params = false) { return Auth.get(ORDERS_FIXER_GET_ALL, params); },
  getMovimientosCompra: function (params) { return Auth.get(COMPRA_MOVIMIENTOS_GET, params); },
  getUsuarios: function (params) { return Auth.get(USUARIOS_LIST_GET, params); },
  getUserSearch: function (params) { return Auth.get(USUARIO_SEARCH_GET, params); },
  getProducto: function (params) { return Auth.get(PRODUCTO_GET_ONE, params); },
  getPetshopsByProductVariation: function (params) { return Auth.get(PETSHOPS_BY_VARIATION_GET, params); },
  postNewPetshop: function (params) { return Auth.post(PETSHOP_NEW_POST, params); },
  postEditPetshop: function (params) { return Auth.post(PETSHOP_EDIT_POST, params); },
  postPricingProducts: function (params) { return Auth.post(UPDATE_PRICING_PRODUCTS, params); },
  postUpdateProductsPricing: function (params) { return Auth.post(UPDATE_PRODUCTS_PRICING, params); },
  postMatchProducts: function (params) { return Auth.post(MATCH_PRICING_EXTERNAL_PRODUCTS, params); },
  getListProducts: function (params) { return Auth.get(LIST_PRODUCTS, params); },
  getOneProduct: function (params) { return Auth.get(ONE_PRODUCT, params); },
  getListPreciosExternos: function (params) { return Auth.get(LIST_EMPRESAS_PRECIOS_EXTERNOS, params); },
  getProductos: function () {
    var productos = [
      {
        id: "1",
        marca: "Old Prince",
        nombre: "Derma Defense",
        foto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/mobile_royal-canin-mini-adult_22",
        categoria: "Alimento",
        animal: "perro",
        calidad: "Super Premium",
        medicado: 0,
        empre: 150.41,
        orden: 10,
        acciones: ":D",
      },
      {
        id: "2",
        marca: "Pro Plan",
        nombre: "Puppy Complete Razas Medianas",
        foto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/mobile_royal-canin-mini-adult_22",
        categoria: "Alimento",
        animal: "perro",
        calidad: "Super Premium",
        medicado: 0,
        empre: 150.41,
        orden: 10,
        acciones: ":D",
      },
      {
        id: "3",
        marca: "Dog Chow",
        nombre: "Cachorros",
        foto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/desktop_pawise-pack-porta-bolsa-con-linterna--repuesto-de-bolsas-x-3-20-unidades-x-rollo_401",
        categoria: "Alimento",
        animal: "perro",
        calidad: "Super Premium",
        medicado: 0,
        empre: 150.41,
        orden: 10,
        acciones: ":D",
      },
    ];
    return productos;
  },

  getSpecialDay: function (params) {
    return Auth.get(GET_SPECIAL_DAY, params);
  },
  deleteSpecialDay: function (params) {
    return Auth.post(DELETE_SPECIAL_DAY, params);
  },
  sendSpecialDay: function (params) {
    return Auth.post(SEND_SPECIAL_DAY, params);
  },
  sendTreggoStatus: function (params) {
    return Auth.post(SEND_TREGGO_STATUS, params);
  },
  getSchedulesActive: function (params) {
    return Auth.get(SCHEDULES_ACTIVES, params);
  },
  getProductosPreciosExternos: function (params) {
    return Auth.get(GET_LIST_PRODUCTS_PRECIOS_EXTERNOS, params);
  },
  getDomiciliosPerfil: function (params) {
    return Auth.get(USUARIO_GET_DOMICILIOS, params);
  },
  getPetshop: function (params) {
    return Auth.get(PETSHOP_GET_ALL_GET, params);
  },
  getUserHappiness: function (params) {
    return Auth.get(USER_HAPPINESS, params);
  },
  getUserComments: function (params) {
    return Auth.get(USER_COMMENTS, params);
  },
  getPetshopList: function (params) {
    return Auth.get(PETSHOPLIST_GET_ALL_GET, params);
  },
  getMegaBuscador: function (params) {
    return Auth.get(MEGA_BUSCADOR_GET, params);
  },
  getMotivosCompraCancelaciones: function () {
    return Auth.get(MOTIVOS_COMPRA_CANCELACION);
  },
  getMotivosCompraIntervenciones: function () {
    return Auth.get(MOTIVOS_COMPRA_INTERVENCION);
  },
  getPanel: function (params) {
    return Auth.get(PANEL_GET_INDICADORES, params);
  },
  getPanelFixer: function (params) {
    return Auth.get(PANEL_FIXER_GET_INDICADORES, params);
  },
  getMarcas: function () {
    return Auth.get(MARCAS_GET_ALL);
  },
  postMarca: function (params) {
    return Auth.post(MARCA_POST, params);
  },
  getCategorias: function () {
    return Auth.get(CATEGORIA_GET_ALL);
  },
  getAnimales: function () {
    return Auth.get(ANIMALES_GET_ALL);
  },
  getUsuarioPerfil: function (params) {
    return Auth.get(USUARIO_GET_ONE, params);
  },
  getDelegacionesPosibles: function (params = false) {
    return Auth.get(DELEGACIONES_POSIBLES_GET, params);
  },
  getComprasPerfil: function (params) {
    return Auth.get(USUARIO_GET_COMPRAS, params);
  },
  getCompraSplitProductos: function (params) {
    return Auth.get(COMPRA_SPLIT_PRODUCTOS_GET, params);
  },
  getNotifications: function (params) {
    return Auth.get(NOTIFICATION_GET, params);
  },
  postNotification: function (params) {
    return Auth.post(NOTIFICATION_POST, params);
  },
  getNotes: function (params) {
    return Auth.get(NOTE_GET, params);
  },
  getCSNotes: function (params) {
    return Auth.get(NOTE_CS_GET, params);
  },
  postNotes: function (params) {
    return Auth.post(NOTE_POST, params);
  },
  postNotificationResponse: function (params) {
    return Auth.post(POST_RESPONSE_NOTIFICATION, params);
  },
  getNotificationHistorial: function (params) {
    return Auth.get(NOTIFICATION_HISTORIAL_GET, params);
  },
  postSendNotification: function (params) {
    return Auth.post(NOTIFICATION_SEND_POST, params);
  },
  postLoginAs: function (params) {
    return Auth.post(USUARIO_LOGIN_AS_POST, params);
  },
  postCompraCompletada: function (params) {
    return Auth.post(COMPRA_POST_COMPLETADA, params);
  },
  postCompraSplit: function (params) {
    return Auth.post(COMPRA_POST_SPLIT, params);
  },
  pausaOrder: function (params) {
    return Auth.post(COMPRA_PAUSE, params);
  },
  toContactByCs: function (params) {
    return Auth.post(TO_CONTACT_CS, params);
  },
  contactedByCs: function (params) {
    return Auth.post(CONTACTED_CS, params);
  },
  getMotivosToContactCS: function (params) {
    return Auth.get(GET_MOTIVOS_TO_CONTACT_CS, params);
  },
  oportunityOrder: function (params) {
    return Auth.post(COMPRA_OPORTUNITY, params);
  },
  postAltaDomicilioPerfil: function (params) {
    return Auth.post(USUARIO_POST_DOMICILIO, params);
  },
  postCompraDomicilio: function (params) {
    return Auth.post(COMPRA_DOMICILIO_POST, params);
  },
  postPerfil: function (params) {
    return Auth.post(USUARIO_PERFIL_POST, params);
  },
  postCompraComentario: function (params) {
    return Auth.post(COMPRA_NOTAS_POST, params);
  },
  postCompraIntervencion: function (params) {
    return Auth.post(COMPRA_INTERVENCION_POST, params);
  },
  postReprogramaCompra: function (params) {
    return Auth.post(REPROGRAMACION_POST, params);
  },
  postDelegarCompra: function (params) {
    return Auth.post(DELEGACIONES_MANUAL_POST, params);
  },
  postEditarProductosCompra: function (params) {
    return Auth.post(PRODUCTOS_EDITAR_POST, params);
  },
  postCancelaCompra: function (params) {
    return Auth.post(COMPRA_CANCELA_POST, params);
  },
  postProblemOrder: function (params) {
    return Auth.post(COMPRA_PROBLEM_POST, params);
  },
  postFollowOrder: function (params) {
    return Auth.post(COMPRA_FOLLOW_POST, params);
  },
  postFollowOrderCancel: function (params) {
    return Auth.post(COMPRA_FOLLOW_CANCEL_POST, params);
  },
  postCompraATarjeta: function (params) {
    return Auth.post(COMPRA_CAMBIA_TARJETA_POST, params);
  },
  postManualPaid: function (params) {
    return Auth.post(COMPRA_PAGO_MANUAL_POST, params);
  },
  postUsuario: function (params) {
    return Auth.post(USUARIO_NUEVO_POST, params);
  },
  getSegmentsLocation: function (location) { return location.split("/"); },
  getRoles: function (params = false) { return Auth.get(GET_ROLES, params); },
  postRol: function (params) { return Auth.post(POST_ROL, params); },
  getPermisos: function (params = false) { return Auth.get(GET_PERMISOS, params); },
  postPermiso: function (params) { return Auth.post(POST_PERMISO, params); },
  savePermisos: function (params) { return Auth.post(POST_PERMISO_CHECK, params); },
  getPermisosRol: function (params) { return Auth.get(GET_PERMISOS_ROL, params); },
  getMenuRol: function (params) { return Auth.get(GET_MENU, params); },
  postMenu: function (params) { return Auth.post(POST_MENU, params); },
  getMenuByRol: function (params) { return Auth.get(GET_MENU_ROL, params); },
  getAllowedRoles: function (params) { return Auth.get(GET_ALLOWED_ROLES, params); },
  saveMenus: function (params) { return Auth.post(POST_MENU_CHECK, params); },
  getRoutes: function (params) { return Auth.get(GET_ROUTES, params); },
  getBreeds: function (params) { return Auth.get(GET_BREEDS, params); },
  getPetProdActive: function (params) { return Auth.get(GET_PETS_PROD_ACTIVE, params); },
  getTypeProduct: function (params) { return Auth.get(GET_TIPO_DE_PRODUCTO, params); },
  getVariation: function (params) { return Auth.get(GET_VARIACION_TEMPLATE, params); },
  postVariationTemplates: function (params) { return Auth.post(POST_VARIACION_TEMPLATE, params); },
  postOrderDigitales: function (params) { return Auth.post(POST_ORDER_DIGITALES, params); },
  postSaveVariationTemplates: function (params) { return Auth.post(SEND_VARIATIONS_TEMPLATES, params); },
  getReproggramingReasons: function (params) { return Auth.get(REPROGRAMMING_REASONS_GET, params); },
  getPetshopsForReproggraming: function (params) { return Auth.get(PETSHOPS_FOR_REPROGRAMMING_GET, params); },
  newBalance: function (params) { return Auth.post(POST_NEW_BALANCE, params); },
  getOrderDeliveriedDigitals: function (params) { return Auth.get(ORDERS_DELIVERIED_DIGITALS, params); },
  resolveWithRefundPichoPesos: function (params) { return Auth.post(RESOLVE_PROBLEM_REFUND_PICHO_PESOS, params); },
  resolveWithRefundCard: function (params) { return Auth.post(RESOLVE_PROBLEM_REFUND_CARD, params); },
  resolveWithCompleteTheOrder: function (params) { return Auth.post(RESOLVE_PROBLEM_COMPLETE_ORDER, params); },
  resolveConfusedProducts: function (params) { return Auth.post(RESOLVE_PROBLEM_CONFUSED, params); },


  // CONFIG
  getConfigs: function (params = false) { return Auth.get(GET_CONFIGS, params); },
  postConfig: function (params) { return Auth.post(POST_CONFIG, params); },
  // FINANCES
  getFinancesVentas: function (params = false) { return Auth.get(GET_TOTAL_VENTAS, params); },
  getFacturacionMensual: function (params = false) { return Auth.get(GET_FACTURACION_MENSUAL, params); },
  getProductsPrices: function (params = false) { return Auth.get(GET_PRODUCTS_PRICES, params); },
  getProductsCompraGrafico: function (params = false) { return Auth.get(GET_PRODUCTS_COMPRAS_GRAFICO, params); },
  // REPORTES
  getReportIndicadores: function (params = false) { return Auth.get(GET_INDICADORES, params); },
  getReportCanceledOrders: function (params = false) { return Auth.get(GET_CANCELED_ORDERS, params); },
  getReportProblemOrders: function (params = false) { return Auth.get(GET_PROBLEM_ORDERS, params); },
  getReportProductsSold: function (params = false) { return Auth.get(GET_PRODUCTS_SOLD, params); },
  getReportIntervened: function (params = false) { return Auth.get(GET_ORDERS_INTERVENED, params); },
  getReportDelegate: function (params = false) { return Auth.get(GET_ORDERS_DELEGATES, params); },
  getPetshopsDelegations: function (params = false) { return Auth.get(GET_PETSHOPS_DELEGATIONS, params); },
  getIntervenedReasons: function (params = false) { return Auth.get(GET_INTERVENED_REASONS, params); },
  getDelegateReasons: function (params = false) { return Auth.get(GET_DELEGATE_REASONS, params); },
  getErrorCuotas: function (params = false) { return Auth.get(GET_ERROR_INSTALLMENT, params); },
  getOrdersInPolygon: function (params = false) { return Auth.get(GET_ORDERS_IN_POLYGON, params); },
  getProductsInPolygon: function (params = false) { return Auth.get(GET_PRODUCTS_IN_POLYGON, params); },
  getOrdersAtencionCliente: function (params) { return Auth.get(PETSHOP_GET_ORDERS_CLIENT, params); },
  getHeatZone: function (params = false) { return Auth.get(GET_HEATZONE, params); },
  getProductsByPuvBasedOnSales: function (params = false) { return Auth.get(GET_PRODUCTS_BY_PUV_BASED_ON_SALES, params); },
  getFinancialCosts: function (params = false) { return Auth.get(GET_FINANCIAL_COSTS, params); },
  getPetshopsBySchedule: function (params = false) { return Auth.get(GET_PETSHOPS_HOURS, params); },
  getGeneralOrders: function (params = false) { return Auth.get(GET_GENERAL_ORDERS, params); },
  getReprogramedOrders: function (params = false) { return Auth.get(GET_REPROGRAMED_ORDERS, params); },
  getMisPichosOrders: function (params = false) { return Auth.get(GET_MISPICHOS_ORDERS, params); },
  getCompleteManualOrders: function (params = false) { return Auth.get(GET_COMPLETE_ORDERS_MANUAL, params); },
  getUsersWithPaymentProblems: function (params = false) { return Auth.get(GET_USERS_WITH_PAYMENT_PROBLEMS, params); },
  getReportSuscripciones: function (params = false) { return Auth.get(GET_SUSCRIPTIONS_REPORT, params); },
  getReportDeletedProdPostEdit: function (params = false) { return Auth.get(DELETED_PROD_POST_EDIT, params); },
  getReportEditionBalance: function (params = false) { return Auth.get(GET_REPORT_EDITION_BALANCE, params); },
  getAssignmentProcess: function (params = false) { return Auth.get(GET_ASSIGNMENT_PROCESS, params); },
  getReportPetshopCreateUpdate: function (params = false) { return Auth.get(GET_CREATE_UPDATE_PETSHOP_REPORT, params); },
  getOutOfHoursDelivery: function (params = false) { return Auth.get(GET_OUT_OF_HOURS_REPORT, params); },
  getContactedByCs: function (params = false) { return Auth.get(GET_REPORT_CONTACTED_CS, params); },
  getReportCupones: function (params = false) { return Auth.get(GET_REPORT_CUPONES, params); },
  getAllCuponesList: function (params = false) { return Auth.get(GET_ALL_CUPONES, params); },
  getReportFabricaPaga: function (params = false) { return Auth.get(GET_REPORT_FABRICA_PAGA, params); },
  getReportComisionesDuplicadas: function (params = false) { return Auth.get(GET_REPORT_COMISION_DUPLICADA, params); },
  getCuotasSinInteres: function (params = false) { return Auth.get(GET_REPORT_CUOTAS_SIN_INTERES, params); },
  getOperativoControlOrders: function (params = false) { return Auth.get(GET_REPORT_OPERATIVE_CONTROL_ORDERS, params); },
  getReportPedidosLogistica: function (params = false) { return Auth.get(GET_REPORT_PEDIDOS_LOGISTICA, params); },
  getMovCuentaCorriente: function (params = false) { return Auth.get(GET_MOV_CUENTA_CORRIENTE, params); },


  // SUSCRIPTIONS
  getSuscriptions: function (params = false) { return Auth.get(GET_SUSCRIPTIONS, params); },
  getSuscriptionMovements: function (params = false) { return Auth.get(GET_SUSCRIPTION_MOVEMENTS, params); },
  processPaymentSuscription: function (params = false) { return Auth.get(PROCESS_PAYMENT_SUSCRIPTION, params); },
  noticePaymentSuscriptions: function (params = false) { return Auth.get(NOTICE_PAYMENT_SUSCRIPTIONS, params); },
  noticeCardExpired: function (params = false) { return Auth.get(NOTICE_CARDS_EXPIRED, params); },
  paymentMovement: function (params) { return Auth.post(PAYMENT_MOVEMENT, params); },
  checkPaymentSuscription: function (params = false) { return Auth.post(CHECK_PAYMENT_SUSCRIPTION, params); },
  addPolicySuscription: function (params = false) { return Auth.post(ADD_POLICY_SUSCRIPTION, params); },
  unsubscribeSuscriptions: function (params) { return Auth.post(POST_UNSUBSCRIBE_SUSCRIPTIONS, params); },
  getSuscriptionsColon: function (params = false) { return Auth.get(GET_SUSCRIPTONS_COLON, params); },
  getRefundsRequested: function (params = false) { return Auth.get(GET_REFUNDS_REQUESTED, params); },
  getRefundsStatusBar: function (params = false) { return Auth.get(GET_REFUNDS_INDICATORS, params); },
  sendRefundInsurranceCarrier: function (params) { return Auth.post(SEND_REFUNDS_INSURRANCE_CARRIER, params); },
  sendFilesRefund: function (params) { return Auth.post(SEND_REFUNDS_FILES, params); },
  getUnSubscribeReason: function (params = false) { return Auth.get(GET_UN_SUSCRIPTONS_REASON, params); },
  getSuscripcionIndicators: function (params = false) { return Auth.get(GET_SUSCRIPTONS_Indicators, params); },
  postReSendDataSuscription: function (params) { return Auth.post(RESEND_DATA_SUSCRIPTION, params); },

  //LOGISTICA
  getZoneDelivery: function (params) { return Auth.get(GET_ZONE_DELIVERY, params); },
  getDeliveryTimes: function (params) { return Auth.get(GET_DELIVERY_TIME, params); },
  getPetshopDeliveryZone: function (params) { return Auth.get(GET_PETSHOPS_DELIVERY_ZONE, params); },
  saveZoneDelivery: function (params) { return Auth.post(POST_ZONE_DELIVERY, params); },
  savePetshopDeliveryZone: function (params) { return Auth.post(POST_PETSHOP_DELIVERY_ZONE, params); },
  getOrdersSpecialZone: function (params) { return Auth.get(ORDERS_SPECIAL_ZONE, params); },
  petshopLogisticCapacity: function (params) { return Auth.post(PETSHOP_LOGISTIC_CAPACITY, params); },
  getBrandsGeneral: function (params) { return Auth.get(GET_BRANDS_GENERAL, params); },
  getCategoryGeneral: function (params) { return Auth.get(GET_CATEGORY_GENERAL, params); },
  getPetshopsPolygons: function (params) { return Auth.get(GET_PETSHOPS_POLYGONS, params); },
  getPetshopsCapacity: function (params) { return Auth.get(GET_PETSHOPS_CAPACITY, params); },

  OurSourcingDelivery: {
    postShippingsFromOrders: function (params) { return Auth.post(POST_SHIPPINGS_FROM_ORDERS, params); },
    getOrdersAdmin: function (params) { return Auth.get(GET_OUTSOURCE_DELIVERY_ADMIN_ORDERS, params); },
    getAgreements: function (params) { return Auth.get(GET_OUTSOURCE_AGREEMENTS, params); },
    getTransportersByAgreement: function (params) { return Auth.get(GET_OUTSOURCE_TRANSPORTERS_BY_AGREEMENT, params); },
  },
  getLogisticVariations: function (params) { return Auth.get(GET_VARIACIONES_LOGISTICA, params); },
  postLogisticVariations: function (params) { return Auth.post(POST_VARIACIONES_LOGISTICA, params); },


  //DISCOUNT
  getDiscountList: function (params = false) { return Auth.get(GET_DISCOUNT, params); },
  discountSave: function (params = false) { return Auth.post(SAVE_DISCOUNT, params); },
  geRreferredActive: function (params) { return Auth.get(GET_REFERRED_ACTIVE, params); },
  changeStatusDiscount: function (params) { return Auth.post(CHANGE_DISCOUNT_STATUS, params); },

};

//ROUTES API CLIENT
const CLIENT_ENDPOINT = "client_controller/";
const CLIENT_HOME_BRANDS_GET = CLIENT_ENDPOINT + "brands/";

//LOGIN API MARKETING MANAGER
const LOGIN_ENDPOINT = "login_controller/";
const GET_ROUTES = LOGIN_ENDPOINT + "routes/";

//ROUTES API MARKETING MANAGER
const MARKETING_MANAGER_ENDPOINT = "marketingapi_controller/";
const MARKETING_GET_LANDING = MARKETING_MANAGER_ENDPOINT + "landing/";
const MARKETING_GET_LANDINGS = MARKETING_MANAGER_ENDPOINT + "landings/";
const MARKETING_EDIT_LANDING = MARKETING_MANAGER_ENDPOINT + "editLanding/";
const MARKETING_NEW_LANDING = MARKETING_MANAGER_ENDPOINT + "newLanding/";

//ROUTES API ACCOUNT MANAGER
const ACCOUNT_MANAGER_ENDPOINT = "accountmanagerapi_controller/";
const ACCOUNT_MANAGER_PETSHOPLIST_GET = ACCOUNT_MANAGER_ENDPOINT + "petshops/";
const ACCOUNT_MANAGER_TRANSFERS_GET = ACCOUNT_MANAGER_ENDPOINT + "transfers/";
const ACCOUNT_MANAGER_BBVA_TXT_GET = ACCOUNT_MANAGER_ENDPOINT + "transfersTxtBBVA/";
const ACCOUNT_MANAGER_ORDER_NO_COMISSION_POST = ACCOUNT_MANAGER_ENDPOINT + "noComissionOrder/";
const ACCOUNT_MANAGER_TRANSFER_MONEY_POST = ACCOUNT_MANAGER_ENDPOINT + "transferMoney/";
const ACCOUNT_MANAGER_TRANSFER_MONEY_PRISMA_POST = ACCOUNT_MANAGER_ENDPOINT + "transferMoneyPrisma/";
const ACCOUNT_ALLOW_CHANGE_DATA_POST = ACCOUNT_MANAGER_ENDPOINT + "allowChangeData/";
const ACCOUNT_CASH_ROW_POST = ACCOUNT_MANAGER_ENDPOINT + "cashRow/";
const ACCOUNT_GET_XUBIO_CLIENTS = ACCOUNT_MANAGER_ENDPOINT + "xubioClients/";
const ACCOUNT_PAUSE_POST = ACCOUNT_MANAGER_ENDPOINT + "pausePetshop/";
const ACCOUNT_INACTIVE_POST = ACCOUNT_MANAGER_ENDPOINT + "inactivePetshop/";
const ACCOUNT_GET_PETSHOP_PROFILE_GET = ACCOUNT_MANAGER_ENDPOINT + "petshopProfile/";
const ACCOUNT_CHANGE_DATE_CALCULATE_INDX_POST = ACCOUNT_MANAGER_ENDPOINT + "changeDateToCalculateIndex/";
const ACCOUNT_MANAGER_INVOICES_GET = ACCOUNT_MANAGER_ENDPOINT + "invoices/";
const ACCOUNT_INVOICE_CREATE = ACCOUNT_MANAGER_ENDPOINT + "invoice/";
const SEND_PET_CATEGORY = ACCOUNT_MANAGER_ENDPOINT + "setCategory/";
const SEND_ACTIVE_AUTO_ACEPT = ACCOUNT_MANAGER_ENDPOINT + "setPuedeAutoAcept/";




//ROUTES ACCOUNTABILITY
const ACCOUNTABILITY_ENDPOINT = "accountabilityapi_controller/";
const ACCOUNTABILITY_GET_CLIENT_PAYMENT = ACCOUNTABILITY_ENDPOINT + "clientPayment/";
const ACCOUNTABILITY_POST_ORDER_SUSPICIUS = ACCOUNTABILITY_ENDPOINT + "orderSuspicius/";
const ACCOUNTABILITY_GET_DASHBOARD = ACCOUNTABILITY_ENDPOINT + "dashboard/";


//ROUTES API PETSHOP
const PETSHOP_ENDPOINT = "proveedorapi_controller/";
const PETSHOP_GET_XUBIO_CLIENTS = PETSHOP_ENDPOINT + "xubioClient/";
const PETSHOP_GET_DASHBOARD = PETSHOP_ENDPOINT + "indicadoresDashboard/";
const PETSHOP_GET_ORDERS = PETSHOP_ENDPOINT + "orders/";
const PETSHOP_GET_ORDER_BY_ID = PETSHOP_ENDPOINT + "order/";
const PETSHOP_GET_NEWS = PETSHOP_ENDPOINT + "news/";
const PETSHOP_CONSULTA_TELEFONO_GET = PETSHOP_ENDPOINT + "phoneOrder/";
const PETSHOP_COMPRA_CONSULTA_STOCK_GET = PETSHOP_ENDPOINT + "consultaStock/";
const PETSHOP_COMPRA_CONSULTA_STOCK_POST = PETSHOP_ENDPOINT + "altaConsultaStock/";
const PETSHOP_COMPRA_CONSULTA_ENTREGA_POST = PETSHOP_ENDPOINT + "altaConsultaEntrega/";
const PETSHOP_COMPRA_CONSULTA_ENTREGA_GET = PETSHOP_ENDPOINT + "consultaEntrega/";
const PETSHOP_COMPRA_DELEGAR_POST = PETSHOP_ENDPOINT + 'delegate/';
const PETSHOP_COMPRA_ACEPTAR_POST = PETSHOP_ENDPOINT + 'preparingorder/';
const PETSHOP_COMPRA_ENTREGADO_POST = PETSHOP_ENDPOINT + 'deliveredorder/';
const PETSHOP_COMPRA_PROBLEMA_POST = PETSHOP_ENDPOINT + 'problemorder/';
const PETSHOP_PAYING_WEEKLY_GET = PETSHOP_ENDPOINT + 'getLiquidacionSemanal/';
const PETSHOP_PAYING_MONTHLY_GET = PETSHOP_ENDPOINT + 'getLiquidacionMensual/';
const PETSHOP_SPECIAL_DAYS_GET = PETSHOP_ENDPOINT + 'specialDays/';
const PETSHOP_SPECIAL_DAYS_POST = PETSHOP_ENDPOINT + 'specialDays/';
const PETSHOP_POLIGONO_POST = PETSHOP_ENDPOINT + 'polygon/';
const PETSHOP_POLIGONO_GET = PETSHOP_ENDPOINT + 'polygon/';
const PETSHOP_PRODUCTOS_GET = PETSHOP_ENDPOINT + 'products/';
const PETSHOP_PRODUCTOS_POST = PETSHOP_ENDPOINT + 'products/';
const PETSHOP_SCHEDULE_POST = PETSHOP_ENDPOINT + 'schedule/';
const PETSHOP_SCHEDULE_GET = PETSHOP_ENDPOINT + 'schedule/';
const PETSHOP_PROFILE_POST = PETSHOP_ENDPOINT + 'profile/';
const PETSHOP_PROFILE_GET = PETSHOP_ENDPOINT + 'profile/';
const PETSHOP_ADDRESS_POST = PETSHOP_ENDPOINT + 'address/';
const PETSHOP_COMPRA_VALIDATE_POST = PETSHOP_ENDPOINT + 'validateOrder/';
const PETSHOP_NOTIFICATION_VIEWED_POST = PETSHOP_ENDPOINT + 'notification/';
const PETSHOP_NOTIFICATION_SURVEY_VIEWED_POST = PETSHOP_ENDPOINT + 'notiSurvey/';
const REPORTE_LIQUIDACION_GET = PETSHOP_ENDPOINT + 'reporte/';
const REPORTE_COMISIONES_GET = PETSHOP_ENDPOINT + 'reporteComision/';
const REPORTE_DESCUENTOS_GET = PETSHOP_ENDPOINT + 'reporteDescuento/';
const PETSHOP_GET_REPORT_STATICS = PETSHOP_ENDPOINT + 'reporteAutoGestion/';
const PETSHOP_ACCOUNT_REGISTRY_GET = PETSHOP_ENDPOINT + 'accountRegistry/';
const PETSHOP_DELEGATE_PREPARING_ORDER_POST = PETSHOP_ENDPOINT + 'delegatePreparingOrder/';
const PETSHOP_MEGA_BUSCADOR_GET = PETSHOP_ENDPOINT + 'megaSearch/';
const GET_TRANSPORTERS = PETSHOP_ENDPOINT + 'transporters/';
const POST_TRANSPORTERS = PETSHOP_ENDPOINT + 'transporter/';
const PETSHOP_INVOICE_EMAIL_POST = PETSHOP_ENDPOINT + 'sendInvoiceByEmail/';
const PETSHOP_NOTIFICATION_HISTORIAL_GET = PETSHOP_ENDPOINT + 'centralNotifications/';
const PETSHOP_CONSOLE_OPEN_POST = PETSHOP_ENDPOINT + 'inspectorElementOpened/';
const UPDATE_SHOW_LABEL_MOOVA = PETSHOP_ENDPOINT + 'updateshowlabel/';
const PETSHOP_GET_REPORT_SOLD_PRODUCTS = PETSHOP_ENDPOINT + 'productosVendidos/';
const PETSHOP_PRODUCTS_CATEGORY = PETSHOP_ENDPOINT + 'productsCategory/';
const PETSHOP_PRODUCTS_CATEGORY_BRANDS = PETSHOP_ENDPOINT + 'productsCategoryByBrands/';
const PETSHOP_AUTO_ACEPT_ORDERS = PETSHOP_ENDPOINT + 'autoAcept/';
const PETSHOP_TRANSPORTERS_MANUAL_ASSIGNATION = PETSHOP_ENDPOINT + 'transpotersManualAssignation/';
const PETSHOP_TRANSPORTERS_ASSIGN_ORDERS = PETSHOP_ENDPOINT + 'transpotersAssignOrders/';
const PETSHOP_TRANSPORTERS_REMOVE_ASSIGNED_ORDERS = PETSHOP_ENDPOINT + 'removeTranspoterFromOrders/';
const PICK_UP_ORDERS = PETSHOP_ENDPOINT + 'trackingOfOutsourcedLogistics/';
const PETSHOP_SCHEDULES_ACTIVES = PETSHOP_ENDPOINT + "schedulesActives/";
const PETSHOP_GET_NOTES = PETSHOP_ENDPOINT + "extraNotes/";
const PETSHOP_GET_CLIENT_PAYMENT = PETSHOP_ENDPOINT + "clientPayment/";
const PETSHOP_GET_RETURN_ORDERS = PETSHOP_ENDPOINT + "returnOrders/";
const PETSHOP_POST_RESOLVE_RETURN_ORDERS = PETSHOP_ENDPOINT + "resolveReturnOrder/";






//ROUTES API TRANSPORTER
const TRANSPORTER_ENDPOINT = "transporterapi_controller/";
const TRANSPORTER_GET_ORDERS = TRANSPORTER_ENDPOINT + "orders/";
const TRANSPORTER_START_DELIVERY_POST = TRANSPORTER_ENDPOINT + "deliveryStart/";
const TRANSPORTER_COMPLETE_DELIVERY_POST = TRANSPORTER_ENDPOINT + "deliveryComplete/";

//ROUTES API LANDING
const LANDING_ENDPOINT = "landing_controller/";
const LANDING_GET = LANDING_ENDPOINT + "landing/";
const LANDING_CREATE_POST = LANDING_ENDPOINT + "insert/";
const LANDING_UPDATE_POST = LANDING_ENDPOINT + "edit/";
const LANDING_ACTIVE_POST = LANDING_ENDPOINT + "active/";
const LANDING_INACTIVE_POST = LANDING_ENDPOINT + "inactive/";


//ROUTES API ATENCION CLIENTE
const ATENCION_CLIENTE_ENDPOINT = "atencioncliente_controller/";
const PANEL_GET_INDICADORES = ATENCION_CLIENTE_ENDPOINT + "panel/";
const CATEGORIA_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "categorias/";
const MARCAS_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "marcas/";
const MARCA_POST = ATENCION_CLIENTE_ENDPOINT + "marca/";
const ANIMALES_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "animales/";
const COMPRA_GET_ONE = ATENCION_CLIENTE_ENDPOINT + "compraById/";
const COMPRA_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "compras/";
const COMPRA_SPLIT_PRODUCTOS_GET = ATENCION_CLIENTE_ENDPOINT + "compraSplitProductos/";
const COMPRA_CAMBIA_TARJETA_POST = ATENCION_CLIENTE_ENDPOINT + "compraCambiaTarjeta/";
const COMPRA_PAGO_MANUAL_POST = ATENCION_CLIENTE_ENDPOINT + "compraPagoManual/";
const COMPRA_CANCELA_POST = ATENCION_CLIENTE_ENDPOINT + "cancelarCompra/";
const COMPRA_POST_SPLIT = ATENCION_CLIENTE_ENDPOINT + "compraSplit/";
const COMPRA_POST_COMPLETADA = ATENCION_CLIENTE_ENDPOINT + "compraCompletada/";
const COMPRA_NOTAS_POST = ATENCION_CLIENTE_ENDPOINT + "editaNota/";
const COMPRA_INTERVENCION_POST = ATENCION_CLIENTE_ENDPOINT + "intervencion/";
const COMPRA_MOVIMIENTOS_GET = ATENCION_CLIENTE_ENDPOINT + "movimientos/";
const COMPRA_DOMICILIO_POST = ATENCION_CLIENTE_ENDPOINT + "cambiaDomicilio/";
const USUARIO_GET_ONE = ATENCION_CLIENTE_ENDPOINT + "usuarioById/";
const USUARIO_SEARCH_GET = ATENCION_CLIENTE_ENDPOINT + "userSearch/";
const USUARIO_PERFIL_POST = ATENCION_CLIENTE_ENDPOINT + "editaCliente/";
const USUARIO_GET_DOMICILIOS = ATENCION_CLIENTE_ENDPOINT + "domiciliosPerfil/";
const USUARIO_POST_DOMICILIO = ATENCION_CLIENTE_ENDPOINT + "altaDomicilio/";
const USUARIO_GET_COMPRAS = ATENCION_CLIENTE_ENDPOINT + "comprasPerfil/";
const USUARIO_NUEVO_POST = ATENCION_CLIENTE_ENDPOINT + "userSave/";
const USUARIO_LOGIN_AS_POST = ATENCION_CLIENTE_ENDPOINT + "loginAs/";
const USUARIOS_LIST_GET = ATENCION_CLIENTE_ENDPOINT + "users/";
const REPROGRAMACION_GET_HORARIOS = ATENCION_CLIENTE_ENDPOINT + "fechasYhoras/";
const REPROGRAMACION_POST = ATENCION_CLIENTE_ENDPOINT + "reprogramaPedido/";
const REPROGRAMMING_REASONS_GET = ATENCION_CLIENTE_ENDPOINT + "reprogrammingReasons/";
const PETSHOPS_FOR_REPROGRAMMING_GET = ATENCION_CLIENTE_ENDPOINT + "getPetshopsForReprogramming/";
const ORDERS_DELIVERIED_DIGITALS = ATENCION_CLIENTE_ENDPOINT + "getDeliverySuccessDigitals/";
const RESOLVE_PROBLEM_REFUND_PICHO_PESOS = ATENCION_CLIENTE_ENDPOINT + "resolveProblemWithRefundPichoPesos/";
const RESOLVE_PROBLEM_REFUND_CARD = ATENCION_CLIENTE_ENDPOINT + "resolveProblemWithRefundCard/";
const RESOLVE_PROBLEM_COMPLETE_ORDER = ATENCION_CLIENTE_ENDPOINT + "resolveProblemWithCompleteOrder/";
const RESOLVE_PROBLEM_CONFUSED = ATENCION_CLIENTE_ENDPOINT + "resolveConfusedProblem/";




const DELEGACIONES_POSIBLES_GET = ATENCION_CLIENTE_ENDPOINT + "delegacionesPosibles/";
const DELEGACIONES_MANUAL_POST = ATENCION_CLIENTE_ENDPOINT + "delegarPedido/";
const PRODUCTO_GET_ONE = ATENCION_CLIENTE_ENDPOINT + "producto/";
const PRODUCTOS_EDITAR_POST = ATENCION_CLIENTE_ENDPOINT + "editarProductos/";
const MEGA_BUSCADOR_GET = ATENCION_CLIENTE_ENDPOINT + "megaBuscador/";
const MOTIVOS_COMPRA_CANCELACION = ATENCION_CLIENTE_ENDPOINT + "motivosCancelacionCompra/";
const MOTIVOS_COMPRA_INTERVENCION = ATENCION_CLIENTE_ENDPOINT + "motivosIntervencionCompra/";
const CONSULTA_STOCK = ATENCION_CLIENTE_ENDPOINT + "consultaStock/";
const CONSULTA_RADIO = ATENCION_CLIENTE_ENDPOINT + "consultaRadio/";
const CONSULTA_ENTREGA = ATENCION_CLIENTE_ENDPOINT + "consultaEntrega/";
const ORDERS_SUSPICIUS = ATENCION_CLIENTE_ENDPOINT + "orderSuspicious/";

const PETSHOP_GET_ALL_GET = ATENCION_CLIENTE_ENDPOINT + "petshops/";
const PETSHOPLIST_GET_ALL_GET = ATENCION_CLIENTE_ENDPOINT + "petshopsList/";
const NOTIFICATION_POST = ATENCION_CLIENTE_ENDPOINT + "notification/";
const NOTIFICATION_GET = ATENCION_CLIENTE_ENDPOINT + "notifications/";
const NOTE_GET = ATENCION_CLIENTE_ENDPOINT + "notas/";
const NOTE_CS_GET = ATENCION_CLIENTE_ENDPOINT + "notesCustomer/";
const NOTE_POST = ATENCION_CLIENTE_ENDPOINT + "notas/";
const NOTIFICATION_SEND_POST = ATENCION_CLIENTE_ENDPOINT + "sendNotification/";
const NOTIFICATION_HISTORIAL_GET = ATENCION_CLIENTE_ENDPOINT + "notificationhistorial/";
const COMPRA_FOLLOW_POST = ATENCION_CLIENTE_ENDPOINT + "followOrder/";
const COMPRA_FOLLOW_CANCEL_POST = ATENCION_CLIENTE_ENDPOINT + "followOrderCancel/";
const COMPRA_PROBLEM_POST = ATENCION_CLIENTE_ENDPOINT + "problemOrder/";
const REPORT_MAIL_GET = ATENCION_CLIENTE_ENDPOINT + "mails/";
const PETSHOPS_BY_VARIATION_GET = ATENCION_CLIENTE_ENDPOINT + "getPetshopsByProduct/";
const PETSHOP_NEW_POST = ATENCION_CLIENTE_ENDPOINT + "petshopsNew/";
const PETSHOP_EDIT_POST = ATENCION_CLIENTE_ENDPOINT + "petshopsEdit/";
const LIST_PRODUCTS = ATENCION_CLIENTE_ENDPOINT + "listProducts/";
const ONE_PRODUCT = ATENCION_CLIENTE_ENDPOINT + "productoone/";
const LIST_EMPRESAS_PRECIOS_EXTERNOS = ATENCION_CLIENTE_ENDPOINT + "empresaspreciosexternos/";
const UPDATE_PRICING_PRODUCTS = ATENCION_CLIENTE_ENDPOINT + "precios/";
const UPDATE_PRODUCTS_PRICING = ATENCION_CLIENTE_ENDPOINT + "updateProductsPricing/";
const MATCH_PRICING_EXTERNAL_PRODUCTS = ATENCION_CLIENTE_ENDPOINT + "productomatch/";
const GET_LIST_PRODUCTS_PRECIOS_EXTERNOS = ATENCION_CLIENTE_ENDPOINT + "listProductsPricing/";
const COMPRA_PAUSE = ATENCION_CLIENTE_ENDPOINT + "pauseOrder/";
const TO_CONTACT_CS = ATENCION_CLIENTE_ENDPOINT + "toContactByCs/";
const CONTACTED_CS = ATENCION_CLIENTE_ENDPOINT + "contactedByCs/";
const GET_MOTIVOS_TO_CONTACT_CS = ATENCION_CLIENTE_ENDPOINT + "motivosAContactarCS/";
const COMPRA_OPORTUNITY = ATENCION_CLIENTE_ENDPOINT + "oportunity/";
const USER_HAPPINESS = ATENCION_CLIENTE_ENDPOINT + "userHappiness/";
const USER_COMMENTS = ATENCION_CLIENTE_ENDPOINT + "userComments/";
const GET_ROLES = ATENCION_CLIENTE_ENDPOINT + "roles/";
const POST_ROL = ATENCION_CLIENTE_ENDPOINT + "rol/";
const GET_PERMISOS = ATENCION_CLIENTE_ENDPOINT + "permisos/";
const GET_PERMISOS_ROL = ATENCION_CLIENTE_ENDPOINT + "permisosRoles/";
const POST_PERMISO = ATENCION_CLIENTE_ENDPOINT + "permiso/";
const POST_PERMISO_CHECK = ATENCION_CLIENTE_ENDPOINT + "permisosCheck/";
const GET_MENU = ATENCION_CLIENTE_ENDPOINT + "menus/";
const POST_MENU = ATENCION_CLIENTE_ENDPOINT + "menu/";
const GET_MENU_ROL = ATENCION_CLIENTE_ENDPOINT + "menusRoles/";
const POST_MENU_CHECK = ATENCION_CLIENTE_ENDPOINT + "menusCheck/";
const GET_ALLOWED_ROLES = ATENCION_CLIENTE_ENDPOINT + "allowedRolesBycreate/";
const GET_BREEDS = ATENCION_CLIENTE_ENDPOINT + "breeds/";
const POST_ORDER_DIGITALES = ATENCION_CLIENTE_ENDPOINT + "orderDigitales/";
const GET_PETS_PROD_ACTIVE = ATENCION_CLIENTE_ENDPOINT + "empresaproducto/";
const GET_TIPO_DE_PRODUCTO = ATENCION_CLIENTE_ENDPOINT + "variaciones/";
const GET_VARIACION_TEMPLATE = ATENCION_CLIENTE_ENDPOINT + "variacion/";
const POST_VARIACION_TEMPLATE = ATENCION_CLIENTE_ENDPOINT + "variacion/";
const GET_SUSCRIPTIONS = ATENCION_CLIENTE_ENDPOINT + "suscriptions/";
const GET_SUSCRIPTION_MOVEMENTS = ATENCION_CLIENTE_ENDPOINT + "suscriptionMovement/";
const PROCESS_PAYMENT_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "processPaymentSuscription/";
const NOTICE_PAYMENT_SUSCRIPTIONS = ATENCION_CLIENTE_ENDPOINT + "noticePaymentSuscriptions/";
const NOTICE_CARDS_EXPIRED = ATENCION_CLIENTE_ENDPOINT + "noticeCardExpired/";
const PAYMENT_MOVEMENT = ATENCION_CLIENTE_ENDPOINT + "paymentMovement/";
const CHECK_PAYMENT_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "checkMovement/";
const ADD_POLICY_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "addPolicySuscription/";
const POST_UNSUBSCRIBE_SUSCRIPTIONS = ATENCION_CLIENTE_ENDPOINT + "unsubscribeSuscriptions/";
const GET_VARIACIONES_LOGISTICA = ATENCION_CLIENTE_ENDPOINT + "variacioneslogistica/";
const POST_VARIACIONES_LOGISTICA = ATENCION_CLIENTE_ENDPOINT + "variacioneslogistica/";
const PETSHOP_GET_ORDERS_CLIENT = ATENCION_CLIENTE_ENDPOINT + "orders/";
const SCHEDULES_ACTIVES = ATENCION_CLIENTE_ENDPOINT + "schedulesActives/";
const PETSHOP_LOGISTIC_CAPACITY = ATENCION_CLIENTE_ENDPOINT + "petshopLogisticCapacity/";
const SEND_VARIATIONS_TEMPLATES = ATENCION_CLIENTE_ENDPOINT + "variationstemplate/";
const GET_SPECIAL_DAY = ATENCION_CLIENTE_ENDPOINT + "specialDay/";
const DELETE_SPECIAL_DAY = ATENCION_CLIENTE_ENDPOINT + "deleteSpecialDay/";
const SEND_SPECIAL_DAY = ATENCION_CLIENTE_ENDPOINT + "sendSpecialDay/";
const SEND_TREGGO_STATUS = ATENCION_CLIENTE_ENDPOINT + "sendTreggoShippingId/";
const GET_UN_SUSCRIPTONS_REASON = ATENCION_CLIENTE_ENDPOINT + "unsubscribeReason/";
const GET_SUSCRIPTONS_Indicators = ATENCION_CLIENTE_ENDPOINT + "suscripcionIndicators/";
const GET_ASSIGNMENT_PROCESS = ATENCION_CLIENTE_ENDPOINT + "movement/";
const GET_PETSHOPS_POLYGONS = ATENCION_CLIENTE_ENDPOINT + "petshops_polygons/";
const RESEND_DATA_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "reSendDataSuscription/";

const GET_USERS_WITH_PAYMENT_PROBLEMS = ATENCION_CLIENTE_ENDPOINT + "userswithpaymentproblems/";

//REOUT API REFER
const REFER_CONTROLER = "referred_controller/";
const POST_NEW_BALANCE = REFER_CONTROLER + "newBalance/";
const REFERRED_GET_MOVE_LIST = REFER_CONTROLER + "moveList/";
const REFERRED_GET_ALL_BALANCE = REFER_CONTROLER + "totalBalance/";
const REFERRED_GET_LIST = REFER_CONTROLER + "referredList/";


//ROUTE API SUSCRIPTION
const SUSCRIPTION_CONTROLER = "suscription_controller/";
const GET_SUSCRIPTONS_COLON = SUSCRIPTION_CONTROLER + "suscriptions/";
const GET_REFUNDS_REQUESTED = SUSCRIPTION_CONTROLER + "refunds/";
const GET_REFUNDS_INDICATORS = SUSCRIPTION_CONTROLER + "indicatorsRefunds/";
const SEND_REFUNDS_INSURRANCE_CARRIER = SUSCRIPTION_CONTROLER + "refundInsurranceCarrier/";
const SEND_REFUNDS_FILES = SUSCRIPTION_CONTROLER + "addFilesRefunds/";

//ROUTE API LOGISTIC CONTROLER
const LOGISTIC_CONTROLER = "delivery_controller/";
const GET_ZONE_DELIVERY = LOGISTIC_CONTROLER + "deliveryZones/";
const POST_ZONE_DELIVERY = LOGISTIC_CONTROLER + "saveDeliveryZone/";
const GET_DELIVERY_TIME = LOGISTIC_CONTROLER + "deliveryTimes/";
const POST_PETSHOP_DELIVERY_ZONE = LOGISTIC_CONTROLER + "savePetshopsZone/";
const GET_PETSHOPS_DELIVERY_ZONE = LOGISTIC_CONTROLER + "petshopDelivery/";

//ROUTE API outsourcing delivery
const OUTSOURCING_LOGISTIC_CONTROLER = "outsourcingdelivery_controller/";
const POST_SHIPPINGS_FROM_ORDERS = OUTSOURCING_LOGISTIC_CONTROLER + "createShippingsFromOrders/";
const GET_OUTSOURCE_DELIVERY_ADMIN_ORDERS = OUTSOURCING_LOGISTIC_CONTROLER + "orders/";
const GET_OUTSOURCE_AGREEMENTS = OUTSOURCING_LOGISTIC_CONTROLER + "agreements/";
const GET_OUTSOURCE_TRANSPORTERS_BY_AGREEMENT = OUTSOURCING_LOGISTIC_CONTROLER + "transportersByAgreement/";



//ROUTE API ATENCION CLIENTE FIXER
const PANEL_FIXER_GET_INDICADORES = ATENCION_CLIENTE_ENDPOINT + "panelFixer/";
const ORDERS_FIXER_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "ordersFixer/";

//ROUTES API NOTIFICATION
const NOTIFICATION_ENDPOINT = "notification_controller/";
const POST_RESPONSE_NOTIFICATION =
  NOTIFICATION_ENDPOINT + "notificationResponse/";

//ROUTES API CONFIG
const CONFIG_ENDPOINT = "config_controller/";
const GET_CONFIGS = CONFIG_ENDPOINT + "configs/";
const POST_CONFIG = CONFIG_ENDPOINT + "config/";

//ROUTES API FINANCES
const FINANCES_ENDPOINT = "finance_controller/";
const GET_TOTAL_VENTAS = FINANCES_ENDPOINT + "ventas/";
const GET_FACTURACION_MENSUAL = FINANCES_ENDPOINT + "facturacion_mensual/";
const GET_PRODUCTS_PRICES = FINANCES_ENDPOINT + "productosprecios/";
const GET_PRODUCTS_COMPRAS_GRAFICO =
  FINANCES_ENDPOINT + "productosPorCompraGrafico/";

//ROUTE API GENERAL_CONTROLER
const GENERAL_CONTROLER = "general_controller/";
const GET_BRANDS_GENERAL = GENERAL_CONTROLER + "marcas/";
const GET_CATEGORY_GENERAL = GENERAL_CONTROLER + "categorias/";
const PRODUCTS_VARIATIONS_GET = GENERAL_CONTROLER + "productsVariations/";
const PRODUCTS_CLEVERTAP_CHANGE_PRICE = ATENCION_CLIENTE_ENDPOINT + "sendClevertapBulletineChangePrice/";

//ROUTES API REPORTS
const REPORT_ENDPOINT = "report_controller/";
const GET_INDICADORES = REPORT_ENDPOINT + "reporteIndicadoresOferta/";
const GET_CANCELED_ORDERS = REPORT_ENDPOINT + "canceledorders/";
const GET_PROBLEM_ORDERS = REPORT_ENDPOINT + "problemorders/";
const GET_PRODUCTS_SOLD = REPORT_ENDPOINT + "productosVendidos/";
const GET_ORDERS_INTERVENED = REPORT_ENDPOINT + "intervened/";
const GET_ORDERS_DELEGATES = REPORT_ENDPOINT + "delegated/";
const GET_PETSHOPS_DELEGATIONS = REPORT_ENDPOINT + "petshopsdelegations/";
const GET_INTERVENED_REASONS =
  ATENCION_CLIENTE_ENDPOINT + "motivosIntervencion/";
const GET_DELEGATE_REASONS = ATENCION_CLIENTE_ENDPOINT + "motivosDelegacion/";
const GET_ERROR_INSTALLMENT = REPORT_ENDPOINT + "installmentError/";
const GET_ORDERS_IN_POLYGON = REPORT_ENDPOINT + "ordersInPolygon/";
const GET_PRODUCTS_IN_POLYGON = REPORT_ENDPOINT + "productssoldbypolygon/";
const GET_HEATZONE = REPORT_ENDPOINT + "heatZoneMap/";
const GET_PRODUCTS_BY_PUV_BASED_ON_SALES =
  REPORT_ENDPOINT + "productsbypuvbasedonsales/";
const GET_FINANCIAL_COSTS = REPORT_ENDPOINT + "financialcosts/";
const GET_PETSHOPS_HOURS = REPORT_ENDPOINT + "petshopsHours/";
const GET_GENERAL_ORDERS = REPORT_ENDPOINT + "orders/";
const GET_REPROGRAMED_ORDERS = REPORT_ENDPOINT + "reprogramedorders/";
const GET_MISPICHOS_ORDERS = REPORT_ENDPOINT + "mispichosOrders/";
const GET_COMPLETE_ORDERS_MANUAL = REPORT_ENDPOINT + "ordersCompleteManual/";
const GET_SUSCRIPTIONS_REPORT = REPORT_ENDPOINT + "suscriptions/";
const GET_REPORT_EDITION_BALANCE = REPORT_ENDPOINT + "ordersWithEditionBalance/";
const GET_REPORT_CONTACTED_CS = REPORT_ENDPOINT + "reportContactedByCS/";
const GET_REPORT_CUPONES = REPORT_ENDPOINT + "reportCupones/";
const GET_ALL_CUPONES = REPORT_ENDPOINT + "reportAllCupones/";
const GET_REPORT_FABRICA_PAGA = REPORT_ENDPOINT + "reportFabricaTePaga/";
const GET_REPORT_COMISION_DUPLICADA = REPORT_ENDPOINT + "reportComisionDuplicada/";
const GET_REPORT_CUOTAS_SIN_INTERES = REPORT_ENDPOINT + "reportCuotasSinInteres/";
const GET_PETSHOPS_CAPACITY = REPORT_ENDPOINT + "logisticCapacity/";
const GET_REPORT_OPERATIVE_CONTROL_ORDERS = REPORT_ENDPOINT + "reportOperativeControl/";
const CONTROL_LOGISTIC_DASHBOARD = REPORT_ENDPOINT + 'logisticControlDashboard/';
const GET_REPORT_PEDIDOS_LOGISTICA = REPORT_ENDPOINT + 'reportPedidosLogistica/';
const GET_MOV_CUENTA_CORRIENTE = REPORT_ENDPOINT + "reportMovCuentaCorriente/";

// -- report create update pause petshops
const GET_CREATE_UPDATE_PETSHOP_REPORT = REPORT_ENDPOINT + "petshopMovementsReportByDate/";
const GET_OUT_OF_HOURS_REPORT = REPORT_ENDPOINT + "outOfHoursDelivery/";


//ROUTES API LOGISTIC
const LOGISTIC_ENDPOINT = "logistic_controller/";
const ORDERS_SPECIAL_ZONE = LOGISTIC_ENDPOINT + "ordersspecialzone/";

//ROUTES API GENERAL
const GENERAL_CONTROLLER = "general_controller/";
const DELETED_PROD_POST_EDIT = GENERAL_CONTROLLER + "reportEdition/";

//ROUTES DISCOUNT
const DISCOUNT_ENDPOINT = "discount_controller/";
const GET_DISCOUNT = DISCOUNT_ENDPOINT + "discounts/";
const SAVE_DISCOUNT = DISCOUNT_ENDPOINT + "discountSave/";
const GET_REFERRED_ACTIVE = DISCOUNT_ENDPOINT + "referredActive/";
const CHANGE_DISCOUNT_STATUS = DISCOUNT_ENDPOINT + "changeStatus/";

//MOTIVOS DLEGACION
const MispichosVars = {
  MOTIVO_DELEGACION_SIN_STOCK: "no tengo stock",
  MOTIVO_DELEGACION_FABRICA: "discontinuado de fábrica",
  MOTIVO_DELEGACION_LOGISTICA: "no puedo entregarlo",
  MOTIVO_DELEGACION_OTROS: "otros",
  MOTIVO_DELEGACION_NOTA: "por nota",
  PROBLEMA_TELEFONO: "el cliente no atendia timbre ni teléfono",
  PROBLEMA_DINERO: "el cliente no tiene el dinero",
  PROBLEMA_LLEGO_TARDE_15: "llego 15 minutos tarde",
  PROBLEMA_LLEGO_TARDE_30: "llego 30 minutos tarde",
  PROBLEMA_LLEGO_TARDE_31: "llego más de 30 minutos tarde",
  /*
  Pedido no llego (no va a entregar pedido)
Pedido incompleto (pedido incompleto)
Producto vencido (incluido en problema con algún producto)
Producto roto (incluido en problema con algún producto)
Producto confundido (incluido en problema con algún producto)
Producto en mal estado (incluido en problema con algún producto)
No entrego factura (otros)
Otros (otros)
  */
  PROBLEMA_PRODUCTO_DESCONTINUADO: 151,
  PROBLEMA_PETSHOP_OTRO: 68,
  PROBLEMA_PETSHOP_PEDIDO_INCOMPLETO: 69,
  PROBLEMA_PETSHOP_ACEPTA_Y_NO_VA_ENTREGAR: 70,
  PROBLEMA_PETSHOP_PREPARANDO_Y_NO_LLEGO: 73,
  PROBLEMA_PETSHOP_PRODUCTO_MAL_ESTADO: 71,
  PROBLEMA_PETSHOP_ENTREGADO_Y_NO_LLEGO: 74,
  PROBLEMA_CLIENTE_OTRO: 67,
  PROBLEMA_CLIENTE_NO_ACEPTA_PEDIDO: 65,
  PROBLEMA_CLIENTE_SE_CONFUNDE_PRODUCTOS: 64,
  PROBLEMA_CLIENTE_NO_RESPOND: 66,
  MOTIVO_DEVOLUCION_PICHO_PESO: 201,
  MOTIVO_DEVOLUCION_TARJETA: 202,
  PROBLEMA_OTRO_ID: 61,
  PROBLEMA_OTRO: "otro",
  PEDIDO_PROCESANDO: 1,
  PEDIDO_PREPARANDO: 2,
  PEDIDO_ENTREGADO: 8,
  PEDIDO_CANCELADO: 32,
  PEDIDO_CON_PROBLEMA: 33,
  ORDEN_DE_RETIRO_PREPARANDO: 1,
  ORDEN_DE_RETIRO_RETIRADO: 3,
  ORDEN_DE_RETIRO_NO_VA_A_RETIRAR: 5,
  ORDEN_DE_RETIRO_CLIENTE_NO_ESTABA: 4,
  PEDIDO_FALTA_PAGAR: 4,
  MEDIO_PAGO_EFECTIVO: 1,
  MEDIO_PAGO_TARJETA: 2,
  GMAPS_KEY: "AIzaSyBLIM3gCGOS3Gker3wSqRUcvjk4lw2uXF0",
  CURRENCY: process.env.REACT_APP_COUNTRY === 'MX' ? 'MXN' : "ARS",
  SHOW_CURRENCY_PREFIX: process.env.REACT_APP_COUNTRY === 'MX' ? '' : "$ ",
  CATEGORIA_CLASIC: {
    km2: 12,
    horarios: 55, //al meno cubre 10 horarios semanales
    productos: 50,
  },
  CATEGORIA_ORO: {
    km2: 18,
    horarios: 80, //al menos cubre 15 horarios semanales
    productos: 70,
  },
  CATEGORIA_PLATINO: {
    km2: 30,
    horarios: 95, //al menos cubre 17 horarios semanales
    productos: 90,
  },
  petshopsCategories: [
    {
      label: "Master Petshop",
      value: "master-petshop",
    },
    {
      label: "Platino",
      value: "platino",
    },
    {
      label: "Oro",
      value: "oro",
    },
    {
      label: "Clásico",
      value: "clasico",
    },
  ],
  MAX_QUANTITY: 15
};




export default Mispichoshelper;
export { MispichosVars, ACCOUNT_MANAGER_BBVA_TXT_GET };

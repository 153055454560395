import React from 'react'
import { AiOutlineLoading } from "react-icons/ai";
import './Button.css';

export default function Button({ type, onPress, loading, labelText }) {

  if (type === 'outline-danger') {
    return (
      <div className='MegaButton OutDanger' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'outline-warning') {
    return (
      <div className='MegaButton OutWarning' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'outline-primary') {
    return (
      <div className='MegaButton OutPrimary' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'outline-success') {
    return (
      <div className='MegaButton OutSuccess' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'danger') {
    return (
      <div className='MegaButton Danger' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'warning') {
    return (
      <div className='MegaButton Warning' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'primary') {
    return (
      <div className='MegaButton Primary' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else if (type === 'success') {
    return (
      <div className='MegaButton Success' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  } else {
    return (
      <div className='MegaButton' onClick={() => onPress()}>
        {loading ? <AiOutlineLoading /> : labelText}
      </div>
    )
  }
}

import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import './TagList.css';


function TagList() {
  // Declara una nueva variable de estado, que llamaremos "count".
  const [cobertura, set_cobertura] = useState(true);
  const [rendimiento, set_rendimiento] = useState(true);
  const [producto, set_producto] = useState(true);
  const [capacidad, set_capacidad] = useState(true);
  const [horario, set_horario] = useState(true);
  const [show, set_show] = useState(false);

  return (
    <div className='TagList'>
      <div className='RowCenter'>
        <div className="TagBox">
          {cobertura && <div className='TagItem' onClick={() => set_cobertura(false)}>cobertura <FontAwesomeIcon icon={faPlus} /></div>}
          {rendimiento && <div className='TagItem' onClick={() => set_rendimiento(false)}>rendimiento <FontAwesomeIcon icon={faPlus} /></div>}
          {producto && <div className='TagItem' onClick={() => set_producto(false)}>producto <FontAwesomeIcon icon={faPlus} /></div>}
          {capacidad && <div className='TagItem' onClick={() => set_capacidad(false)}>capacidad <FontAwesomeIcon icon={faPlus} /></div>}
          {horario && <div className='TagItem' onClick={() => set_horario(false)}>horario <FontAwesomeIcon icon={faPlus} /></div>}
        </div>
        <input onClick={() => set_show(!show)} type="text" placeholder='Agrega los filtros que necesites :D' />
      </div>
      {show &&
        <ul className="SelectTag">
          <li onClick={() => set_cobertura(!cobertura)} className={cobertura && 'activo'}><FontAwesomeIcon icon={faCheckSquare} /> Cobertura</li>
          <li onClick={() => set_rendimiento(!rendimiento)} className={rendimiento && 'activo'}><FontAwesomeIcon icon={faCheckSquare} /> Rendimiento</li>
          <li onClick={() => set_producto(!producto)} className={producto && 'activo'}><FontAwesomeIcon icon={faCheckSquare} /> Producto</li>
          <li onClick={() => set_capacidad(!capacidad)} className={capacidad && 'activo'}><FontAwesomeIcon icon={faCheckSquare} /> Capacidad</li>
          <li onClick={() => set_horario(!horario)} className={horario && 'activo'}><FontAwesomeIcon icon={faCheckSquare} /> Horario</li>
        </ul>}
    </div>
  );
}

export default class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
    }
  }

  showElement = () => {
    if (this.state.type === 'outline-danger') {
      return (
        <div className='TagItem OutDanger'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'outline-warning') {
      return (
        <div className='TagItem OutWarning'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'outline-primary') {
      return (
        <div className='TagItem OutPrimary'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'outline-success') {
      return (
        <div className='TagItem OutSuccess'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'danger') {
      return (
        <div className='TagItem Danger' onClick={this.props.onClick}>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'warning') {
      return (
        <div className='TagItem Warning'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'primary') {
      return (
        <div className='TagItem Primary'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === 'success') {
      return (
        <div className='TagItem Success'>
          {this.props.labelText}
        </div>
      )
    } else if (this.state.type === '') {
      return (
        <div className='TagItem'>
          {this.props.labelText}
        </div>
      )
    }
  }
  render() {
    return (
      <>
        {this.showElement()}
      </>
    )
  }
}


export { TagList };
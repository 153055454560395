// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read http://bit.ly/CRA-PWA

import Mispichoshelper from "./utils/Mispichoshelper";
import AuthService from "./utils/AuthService";

const Auth = new AuthService();

/*
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);*/

// ============== Sound ==============

function Sound(source, loop) {
  this.source = source;
  this.loop = loop;
  var son;
  this.son = son;
  this.finish = false;
  this.stop = function () {
    document.body.removeChild(this.son);
  }
  this.start = function () {
    if (this.finish) {
      console.log('SWC: finish sound');
      return false
    };
    try {
      this.father = document.createElement("audio");
      this.father.setAttribute("autoplay", "true");
      this.loop && this.father.setAttribute("loop", this.loop);
      this.son = document.createElement("source");
      this.son.setAttribute("src", this.source);

      this.father.appendChild(this.son);
      document.body.appendChild(this.father);
    } catch (err) {
      console.log('SWC: failed start sound', err);
    }

  }
  this.remove = function () {
    document.body.removeChild(this.father);
    this.finish = true;
  }
  this.init = function (volume, loop) {
    this.finish = false;
    this.volume = volume;
    this.loop = loop;
  }
}

// ============== Listen SW mensajes ==============

const soundDog = window.location.protocol + "//" + window.location.host + "/soundPublic/dogs_singing.mp3";
const soundPedidoDemorado = window.location.protocol + "//" + window.location.host + "/soundPublic/pedido_demorado.mp3";


// ============== REGISTRAR SW ==============
export function register(config) {
  console.log("SWC: REGISTRA SERVICE WORKER")
  //if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {

  if ('serviceWorker' in navigator) {
    console.log('SWC: serviceWorker in navigator');
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      console.log('SWC: sale service workner por ruta distinta');
      return;
    }

    //window.addEventListener('load', () => {

    if (!localStorage.getItem('mp_token_web') || !process.env.REACT_APP_PUSH_SERVICE) {
      console.log('SWC: no tengo token para pedir service worker');
      return;
    }

    //const swUrl = process.env.NODE_ENV === 'production'?`${process.env.PUBLIC_URL}/service-worker.js`:`${process.env.PUBLIC_URL}/pushServiceWorker.js?token=`+localStorage.getItem('mp_token_web')+'&env='+process.env.REACT_APP_PUSH_SERVICE;
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js?token=` + localStorage.getItem('mp_token_web') + '&env=' + process.env.REACT_APP_PUSH_SERVICE + '&14022020';

    if (false) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl, config);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then((registration) => {
        console.log(
          'This web app is being served cache-first by a service ' +
          'worker. To learn more, visit http://bit.ly/CRA-PWA'
        );
      });
    } else {
      // Is not localhost. Just register service worker
      registerValidSW(swUrl, config);
    }

    //});
  } else {
    console.log('serviceWorker SIN navigator');
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {

      // SAVE SUBSCRIPTION
      if (registration.installing) {
        console.log('SWC: === Service worker installing');
      } else if (registration.waiting) {
        console.log('SWC: === Service worker waiting');
      } else if (registration.active) {
        console.log('SWC: === Service worker active');
        saveSubscription(registration)
      }

      // console.log('SWC: PASO 1 ');
      registration.onupdatefound = () => {
        // console.log('SWC:PASO 2');
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            // console.log('SWC: PASO 3')
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                'tabs for this page are closed. See http://bit.ly/CRA-PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
            // console.log('SWC: PASO 4');
            // Recargamos aca para que una vez creado el service worker el cliente agregue el listener de esuchar mensajes
            // window.location.reload()
            // Redireccionamos a Pantalla de Carga - simular reload
            window.location.href = "/loading";
          }
        };
        // Recargamos aca para que una vez creado el service worker el cliente agregue el listener de esuchar mensajes
        // window.location.reload();

        // Redireccionamos a Pantalla de Carga - simular reload
        // console.log('SWC: PASO 5');
        // window.location.href = "/loading";
      };
    }).then(() => {

      // LISTEN TO MESSAGES
      navigator.serviceWorker.ready.then((registration) => {
        console.log('SWC: Service worker Listo!');

        navigator.serviceWorker.addEventListener('message', function (event) {
          try {
            console.log('SWC: Recibe mensaje del service worker ', event.data);
            //var response = JSON.parse(event.data);
            //console.log("CONDICION 1: ", !event.data.action)
            //console.log("CONDICION 2: ", event.data.action !== "")

            // ************* PUSH ENTRANTE
            if (!event.data.action && event.data.action !== "") {

              // LocalStorage
              //console.log("guarda local storage!!!!", localStorage.getItem('NOTIFICATION_RECEIVED'))
              localStorage.setItem('NOTIFICATION_RECEIVED', 1);
              //console.log("guarda local storage!!!!", localStorage.getItem('NOTIFICATION_RECEIVED'))

              // Play sound

              let sound = event.data.soundType === 2 ? soundPedidoDemorado : soundDog
              console.log('SWC: sound ', sound);
              var foo = new Sound(sound, false);
              foo.start();
              setTimeout(() => {
                foo.remove();
                event.data.reset && window.location.reload();
              }, 4000)


              // ************* PUSH ACTIONS
            } else {

              // WEBCHECK
              if (event.data.type === "webCheck") {

                if (event.data.action === "si") {
                  console.log("SWC: Tu respuesta en cliente es Si")
                }

                if (event.data.action === "no") {
                  console.log("SWC: Tu respuesta en cliente es No")
                }

                // Registrar Respuesta en el historial
                const params = { id: event.data.notificationId, response: event.data.action };

                Mispichoshelper.postNotificationResponse(params).then((response) => {
                  console.log('SWC: postNotificationResponse: ', response)
                })

              }

              // WEBCLICKORDER
              if (event.data.type === "webClickOrder") {
                console.log("SWC: redireccionar a compra: ", event.data.data)
                window.location.href = '/petshop/dashboard?forceOrder=' + event.data.data.com_id;
              }
            }

          } catch (e) {
            console.log('SWC: listenWorker => catch add event listeer', e)
          }

        })

      });
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

// ============== Funciones ==============

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        console.log('SWC: no encontramos el sw ' + swUrl);
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('SWC: No internet connection found. App is running in offline mode.');
    });
}

async function saveSubscription(registration) {
  try {
    console.log("SWC: SAVE SUBSCRIPTION")
    const applicationServerKey = urlB64ToUint8Array('BLxN2R4VQ9uhPXZ1uPMn92ldtwg9VCX2F9WjH96RVjQQewRd2u7l8Z5Khr_EswL-1KDHYHrZNqyKzn3OwayP3uw');
    const options = { applicationServerKey, userVisibleOnly: true };
    const subscription = await registration.pushManager.subscribe(options);
    const response = await sendSubscription(subscription);
    console.log('SWC: tratando de resgistrar push', response);
  } catch (err) {
    console.log('SWC: Error event listener activate ', err)
  }
}

const urlB64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

// saveSubscription saves the subscription to the backend
const sendSubscription = async subscription => {
  const SERVER_URL = process.env.REACT_APP_PUSH_SERVICE;
  var token = Auth.getToken();
  const data = { 'token': token, subscription: subscription };
  const response = await fetch(SERVER_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return response.json()
}

// ============== Exports ==============

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

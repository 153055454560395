import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrin, faFrown, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

function UserHappines({ badExperiences, goodExperiences, badExperienceLastOrder }) {
  var cantBadExperiences = badExperiences;
  var cantGoodExperiences = goodExperiences;
  var lastOrderExperience = badExperienceLastOrder && badExperienceLastOrder.mala_experiencia ? 'tomato' : 'mediumseagreen';
  var iconLastOrder = badExperienceLastOrder && badExperienceLastOrder.mala_experiencia ? faFrown : faGrin;

  return (
    <div className='UserHappines'>
      <div className='RowCenter'>
        <FontAwesomeIcon color={lastOrderExperience} icon={iconLastOrder} />
        <h6>Pedido Anterior</h6>
        <small>
          ({badExperienceLastOrder ? badExperienceLastOrder.fecha : 'primera compra'})
        </small>
      </div>

      <div className='RowCenter'>
        <FontAwesomeIcon icon={faFolderOpen} color={'#949494'} />
        <h6>Historico de pedidos</h6>
        <small style={{ color: 'tomato' }}>
          <span style={{ color: 'tomato' }}>({cantBadExperiences})</span> | <span style={{ color: 'mediumseagreen' }}>({cantGoodExperiences})</span>
        </small>
      </div>


    </div>
  )

}

export default UserHappines;
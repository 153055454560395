import React, { useEffect, useState } from 'react'
import { DatePicker, ConfigProvider, Button, Table, Modal, Select } from 'antd';
import Mispichoshelper from '../utils/Mispichoshelper';
import moment from 'moment';
import es_ES from 'antd/es/locale-provider/es_ES';
import { Download } from "../components/dashboard-elements/TablaPedidos";
import LoadingOmar from "../components/OmarComponets/LoadingOmar";
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

const Option = Select.Option;

function ReporteDelegacion() {
    const [loading, setLoading] = useState(true);
    const [filterFrom, setFilterFrom] = useState(moment().subtract(7, 'd'));
    const [filterTo, setFilterTo] = useState(moment());
    const [petshop, setPet] = useState([]);
    const [petshopSelected, setPetshopSelected] = useState(null);
    const [petshopSelectedDelivery, setPetshopSelectedDelivery] = useState(null);
    const [petshopSelectedNotDelivery, setPetshopSelectedNotDelivery] = useState(null);
    const [data, setData] = useState([]);
    const [cant_total_rows, setCant] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(50);
    const [check, setCkeck] = useState(false);
    const [position, setPosition] = useState('bottom');


    useEffect(() => {
        if (petshop.length === 0) {
            getPetshop();
        }
        getDelagateOrders()
        // eslint-disable-next-line
    }, [pagination])

    const onChangeDate = (date, dateString) => {

        if (date[0] && date[1]) {
            setFilterFrom(date[0])
            setFilterTo(date[1])
        }
    }

    const getPetshop = async () => {
        await Mispichoshelper.getPetshopList({ full: 1 }).then((response) => setPet(response.payload));
    }

    const getDelagateOrders = (page) => {

        setLoading(true)
        let limit = pagination;
        let auxPage = page ? page - 1 : 0;
        let params = {
            desde: filterFrom ? moment(filterFrom).format('YYYY-MM-DD') : null,
            hasta: filterTo ? moment(filterTo).format('YYYY-MM-DD') : null,
            puv_id: petshopSelected ? petshopSelected : null, // id del punto de venta que delego
            puv_delivery: petshopSelectedDelivery ? petshopSelectedDelivery : null, // id del punto de venta actual/entrego
            puv_not_delivery: petshopSelectedNotDelivery ? petshopSelectedNotDelivery : null, // id del punto de venta no actual/entrego
            limit: limit,
            page: auxPage,
        }

        Mispichoshelper.getPetshopsDelegations(params).then((response) => {
            setLoading(false)
            if (response.payload) {

                setPage(response.payload.page)
                setCant(response.payload.cant_total_rows)
                setData(response.payload.rows)
            }
        });
    }

    const getAllFiles = () => {
        if (!check) {
            var inicio = filterFrom ? filterFrom.format('YYYY-MM-DD') : null;
            var end = filterTo ? filterTo.format('YYYY-MM-DD') : null;
            var diffDays = moment(end).diff(inicio, 'days') + 1  // =1
            if (diffDays > 90) {
                Modal.error({
                    title: 'Importante!',
                    content: 'Para ejecutar esta funcionalidad no puede superar el rango de 90 dias',
                });
                return;
            } else {
                setCkeck(true);
                setPagination(3000);
                setPosition('top');
            }
        } else {
            setCkeck(false);
            setPagination(50);
            setPosition('bottom');
        }
    }

    const downloadToExcel = (cols, orders) => {
        return (
            <div>
                <label>
                    <input type="checkbox" checked={check} onChange={() => { getAllFiles() }} /> Descargar todos los resultados (hasta 3.000 por pagina)
                </label>
                <Download cols={cols} orders={orders} />
            </div>)
    }

    const columns = [

        {
            title: 'Nro de pedido',
            dataIndex: 'Compra',
            key: 'Compra',

        },
        {
            title: 'Fecha Creada',
            dataIndex: 'Fecha Creada',
            key: 'Fecha Creada',
            width: 150
        },
        {
            title: 'Fecha Pedida',
            dataIndex: 'Fecha Pedida',
            key: 'Fecha Pedida',
            width: 150
        },
        {
            title: 'Fecha Delegacion',
            dataIndex: 'Fecha Delegacion',
            key: 'Fecha Delegacion',
            width: 150
        },
        {
            title: 'Petshop Delegó',
            dataIndex: 'Petshop Delego',
            key: 'Petshop Delego',
            width: 150
        },
        {
            title: 'Petshop Actual/Entregó',
            dataIndex: 'Petshop Actual/Entrego',
            key: 'Petshop Actual/Entrego',
            width: 150
        },
        {
            title: 'Productos',
            dataIndex: 'productos',
            key: 'productos'
        },
        {
            title: 'valor de pedido',
            dataIndex: 'total',
            key: 'total'
        },
    ];
    return (
        <div className='GlobalStructure'>
            <section className='Card Sombra'>
                <div className="RowCenter SpaceBetween">
                    <div className="RowCenter">
                        <div style={{ width: '250px', paddingRight: "1%" }}>
                            <h5>Periodo a Evaluar (Fecha Pedida)</h5>
                            <ConfigProvider locale={es_ES}>
                                <RangePicker
                                    picker="month"
                                    onChange={(date, dateString) => onChangeDate(date, dateString)}
                                    format={dateFormat}
                                    defaultValue={[filterFrom, filterTo]}
                                />
                            </ConfigProvider>
                        </div>
                        <div style={{ width: '250px', paddingRight: "1%" }}>
                            <h5>Petshop Delegó</h5>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                                placeholder="Selecciona un petshop"
                                onChange={(evt) => setPetshopSelected(evt)}
                            >
                                <Option value={0}>Todos</Option>
                                {petshop.map((cada_pet, iPet) => <Option key={iPet} value={cada_pet.id}>{cada_pet.nombre}</Option>)}
                            </Select>
                        </div>
                        <div style={{ width: '250px', paddingRight: "1%" }}>
                            <h5>Petshop Actual/Entregó</h5>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                                placeholder="Selecciona un petshop"
                                onChange={(evt) => setPetshopSelectedDelivery(evt)}
                            >
                                <Option value={0}>Todos</Option>
                                {petshop.map((cada_pet, iPet) => <Option key={iPet} value={cada_pet.id}>{cada_pet.nombre}</Option>)}
                            </Select>
                        </div>
                        <div style={{ width: '250px', paddingRight: "1%" }}>
                            <h5>Petshop No Actual/Entregó</h5>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                                placeholder="Selecciona un petshop"
                                onChange={(evt) => setPetshopSelectedNotDelivery(evt)}
                            >
                                <Option value={0}>Todos</Option>
                                {petshop.map((cada_pet, iPet) => <Option key={iPet} value={cada_pet.id}>{cada_pet.nombre}</Option>)}
                            </Select>
                        </div>

                    </div>
                    <div style={{ width: '250px' }}>
                        <Button loading={loading} type="primary" style={{ marginTop: "24px", width: "100%" }} onClick={() => getDelagateOrders()} >
                            Filtrar
                        </Button>
                    </div>
                </div>
            </section>
            {loading ?
                <LoadingOmar fullHeight={true} />
                :
                <section className='Card'>
                    <div className='RowCenter SpaceBetween'>
                        <span>{"Resultados " + cant_total_rows}</span>
                        {downloadToExcel(columns, data)}
                    </div>
                    <Table
                        dataSource={data}
                        columns={columns}
                        pagination={{
                            position: position,
                            pageSize: pagination,
                            current: page,
                            total: cant_total_rows,
                            onChange: (page) => getDelagateOrders(page)
                        }}
                    />
                </section>
            }
        </div >
    )
}
export default ReporteDelegacion
import React from "react";
import { Icon } from 'antd';
import Mispichoshelper, { MispichosVars } from '../utils/Mispichoshelper';
import AtencionFilterDay from "../components/dashboard-elements/AtencionFilterDay";
import TablaPedidosOrderProblem from "../components/dashboard-elements/TablaPedidosOrderProblem";
import LoadingOmar from "../components/OmarComponets/LoadingOmar";


const PROBLEMAS = { GRUPAL_OTROS: 6, POR_MIS_PICHOS: 1, POR_CLIENTE: 2, POR_PETSHOP: 3, NUNCA_LLEGAMOS: 4 };
var PROBLEMAS_CATEGORY = {
  [PROBLEMAS.POR_MIS_PICHOS]: [27],
  [PROBLEMAS.POR_CLIENTE]: [
    MispichosVars.PROBLEMA_CLIENTE_NO_RESPOND,
    MispichosVars.PROBLEMA_CLIENTE_OTRO,
    MispichosVars.PROBLEMA_CLIENTE_NO_ACEPTA_PEDIDO,
    MispichosVars.PROBLEMA_CLIENTE_SE_CONFUNDE_PRODUCTOS
  ],
  [PROBLEMAS.NUNCA_LLEGAMOS]: [
    MispichosVars.PROBLEMA_PETSHOP_ACEPTA_Y_NO_VA_ENTREGAR,
    MispichosVars.PROBLEMA_PETSHOP_ENTREGADO_Y_NO_LLEGO,
    MispichosVars.PROBLEMA_PETSHOP_PREPARANDO_Y_NO_LLEGO
  ],
  [PROBLEMAS.POR_PETSHOP]: [
    MispichosVars.PROBLEMA_PETSHOP_PEDIDO_INCOMPLETO,
    MispichosVars.PROBLEMA_PETSHOP_PRODUCTO_MAL_ESTADO,
    MispichosVars.PROBLEMA_PETSHOP_OTRO
  ],
  [PROBLEMAS.GRUPAL_OTROS]: [
    MispichosVars.PROBLEMA_PETSHOP_PEDIDO_INCOMPLETO,
    MispichosVars.PROBLEMA_PETSHOP_PRODUCTO_MAL_ESTADO,
    MispichosVars.PROBLEMA_PETSHOP_OTRO,
    MispichosVars.PROBLEMA_CLIENTE_NO_RESPOND,
    MispichosVars.PROBLEMA_CLIENTE_OTRO,
    MispichosVars.PROBLEMA_CLIENTE_NO_ACEPTA_PEDIDO,
    MispichosVars.PROBLEMA_CLIENTE_SE_CONFUNDE_PRODUCTOS,
    27
  ]
};


class DashboardAtencionRolProblema extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      panel: [],
      loading: true,
      numTab: PROBLEMAS.NUNCA_LLEGAMOS,
      date: null,
      dateActivo: "ninguno",
      showFilterDay: false
    };
  }

  componentDidMount() {
    this.getPanel();
  }

  getPanel = () => {
    this.setState({ loading: true }, () => {
      let params = {
        date: this.state.date ? this.state.date : null
      };
      Mispichoshelper.getPanelFixer(params).then(this.cargaPanel);
    })
  }
  cargaPanel = (panel) => {
    console.log('aca');
    var filterTypeProblemsForTable = panel.payload.map(eachProblem => { return { text: eachProblem.mot_nombre, value: eachProblem.mot_nombre, } });
    console.log('filter problems', filterTypeProblemsForTable);
    this.setState({ panel: panel.payload, filterTypeProblemsForTable: filterTypeProblemsForTable, loading: false });
  }

  cambiaTab(tab) {
    this.setState({ numTab: tab });
  }

  toggleFiltros = () => {
    this.setState({ showFilterDay: !this.state.showFilterDay })
  }

  changeDate = (date, dateActivo = "ninguno") => {
    console.log('ESTE ES EL DATE', date)
    this.setState({ date: date, dateActivo: dateActivo }, () => {
      this.getPanel();
    });
  }

  // Limpiar fecha
  reset() {
    this.changeDate()
  }

  getCantForCategory = (type) => {
    var cant = 0;
    var arrayList = PROBLEMAS_CATEGORY[type];
    this.state.panel.map(eachTile => {
      if (arrayList.includes(eachTile.mot_id)) {
        cant = cant + eachTile.cant
      }
      return cant
    });
    return cant;
  }

  muestraIndicador(indicador, titulo, background, icon, key) { // Indicador activo

    var valorIndicador = this.getCantForCategory(indicador);
    console.log('cantidad para' + indicador, valorIndicador);
    if (!valorIndicador) return this.muestraIndicadorApagado(titulo, 'gris', icon, key);

    var default_styles = background;
    //    var tab= key;
    return (
      <article className={default_styles} onClick={() => this.cambiaTab(indicador)}>
        <Icon className='iconPanel' type={icon} />
        <p className='pill'>{valorIndicador}</p>
        <span>{titulo}</span>
      </article>
    );
  }
  muestraIndicadorApagado(titulo, background, icon) { // Indicador desabilitado
    var default_styles = 'disabledPanel';
    return (
      <article className={default_styles}>
        <Icon className='iconPanel' type={icon} />
        <p className='pill'>0</p>
        <span>{titulo}</span>
      </article>
    );
  }
  render() {

    if (this.state.loading) {
      console.log('entra loading');
      return (<LoadingOmar fullHeight={true} />);
    } else {

      return (
        <section className=''>

          <section className='TileConteiner'>
            {this.muestraIndicador(PROBLEMAS.NUNCA_LLEGAMOS, 'Nunca llegamos', 'rojo indicador', 'reconciliation', 1)}
            {this.muestraIndicador(PROBLEMAS.GRUPAL_OTROS, 'Otros problemas', 'rojo indicador', 'reconciliation', 2)}
          </section>

          <div className="GlobalStructure">

            <AtencionFilterDay date={this.state.date} changeDate={this.changeDate} activo={this.state.dateActivo} reset={this.reset} />

            <TablaPedidosOrderProblem
              activeKey={this.state.numTab}
              //activeKey={1}
              panel={this.state.panel}
              filterTypeProblemsForTable={[
                { text: 'otro', value: 'otro' },
                { text: 'el cliente no atendia timbre ni teléfono', value: 'el cliente no atendia timbre ni teléfono' },
                { text: 'cliente se confunde de productos', value: 'cliente se confunde de productos' },
              ]}
              date={this.state.date}
              changeDate={this.changeDate}
              activo={this.state.dateActivo}
              reset={this.reset}
              toggleFiltros={() => { this.toggleFiltros() }}
            />

          </div>

        </section>


      )
    }
  }
};



export default DashboardAtencionRolProblema;


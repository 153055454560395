import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Tag, Button, Drawer } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faCalendarDay } from '@fortawesome/free-solid-svg-icons'

import './Reset.css';
import Mispichoshelper, { MispichosVars } from "../../utils/Mispichoshelper";
import ReactExport from "react-export-excel";
import UserHappines from "../compra/UserHappines";
import CompraReprogramar from "./CompraReprogramar";
import { VerNotas } from "./CompraAcciones";
import LoadingOmar from "../OmarComponets/LoadingOmar";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: MispichosVars.CURRENCY,
});


class TablaPedidos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showToolBox: false,
      rows: [],
      orderSelected: {},
      activeTab: this.props.activeKey,
      drawerView: 'default',
      loading: true,
      pendientesMPPausa: [],
      pendienteAceptar: [],
      comments: [],
      userHappiness: {},
      pendientePago: [],
      pendientesMP: [],
      aSolucionar: [],
      aceptadoEntregado: [],
      split: [],
      following: [],
      date: this.props.date ? this.props.date : null
    };
  }

  //Drawer ------------------


  onCloseDrawer = () => {
    this.setState({
      orderSelected: {}, drawerView: 'default',
    });
  };

  callback = (key) => {
    console.log(key);
  }

  //-------------------------


  componentDidMount() {
    this.cargarTabla()
  }

  componentWillReceiveProps(next_props) {

    // Cambio de Tab
    if (next_props.activeKey !== this.props.activeKey) {
      this.setState({ activeTab: next_props.activeKey }, () => {
        // Cambio de activeKey
        this.cargarTabla()
      });
    }

    // Cambio de fecha
    if (next_props.date !== this.props.date) {
      this.setState({ date: next_props.date }, () => {
        this.cargarTabla()
      });
    }

  }

  cargarTabla() {
    this.setState({ loading: true }, () => {
      this.buscaPedidos(this.state.activeTab, this.saveData);
    });

  }
  buscaPedidos(estadoCompra, safe, pageNumber = 1) {
    let date = this.state.date ? this.state.date : null;
    Mispichoshelper.getOrdersFixer({ mot_id: estadoCompra, page: pageNumber, date: date }).then(safe);
  }

  // TABLA PEDIDOS
  saveData = (data) => {
    this.setState({ rows: data.payload.rows, cantTotal: data.payload.cant_total_rows }, () => {
      this.setState({ loading: false });
    });
  }

  setCompraSelected = (record) => {
    this.props.history.push('/compras?compra_id=' + record.id);
  }


  changePage = (title, numberPage, filters, sorter, extra) => {
    console.log('filters');
    this.buscaPedidos(this.state.activeTab, this.saveData, numberPage);
  }

  downloadToExcel = (cols, orders) => {
    if (this.state.downloadExcel) {
      return <Download cols={cols} orders={orders} />
    }
    return (
      <Button type='link' onClick={() => this.setState({ downloadExcel: true })}>
        Descargar en Excel
      </Button>
    )
  }


  // Change Data
  changeDateActive(date) {
    this.props.changeDate(date)
  }

  loadComments = async () => {
    var comments = await Mispichoshelper.getUserComments({ com_id: this.state.orderSelected.id });
    this.setState({ comments: comments.payload });

  }

  openDrawer = async (record) => {
    var userHappiness = await Mispichoshelper.getUserHappiness({ user_id: record.cliente_id, com_id: record.id });
    this.setState({ orderSelected: record, userHappiness: userHappiness.payload });
  }


  openInZoho = (email) => {
    window.open('https://crmplus.zoho.com/mispichos/index.do/cxapp/support/mispichos/ShowHomePage.do#Contacts/search/CurDep/' + email);
  }

  table(cant_pedidos, pedidos, title, key, icon, background, columnsToUse) {


    let headerClass = background ? 'TableBoxHeader ' + background : 'TableBoxHeader'
    console.log('quiere mostrar', cant_pedidos, pedidos)
    return (
      <article className={this.props.activeKey === key ? "ShowElement" : 'ShowElement'} >

        <div className={headerClass}>
          <span>{title + " " + cant_pedidos}</span>
          {this.downloadToExcel(columnsToUse, pedidos)}
        </div>
        <div className='TableBody'>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => { this.openDrawer(record) }
              };
            }}
            columns={columnsToUse} dataSource={pedidos}
            pagination={
              {
                position: 'bottom', pageSize: 20, total: cant_pedidos,
                onChange: (pageNumber, filters, sorter, extra) => this.changePage(title, pageNumber, filters, sorter, extra)
              }
            }
            scroll={{ x: 2500 }}
            bordered

            size={'middle'}
          />
        </div>
      </article>
    );
  }

  drawerContet = () => {
    console.log('order selected', this.state.orderSelected)
    return (

      <Drawer
        title="Shortcut by Omi"
        width={400}  // ---------------------> Aca se puede controlar el ancho
        onClose={this.onCloseDrawer}
        visible={this.state.orderSelected.id !== undefined}
      >

        <div className='DrawerShortcut'>
          <h4>
            Pedido #{this.state.orderSelected.id}
          </h4>
          {this.state.userHappiness.lastOrder !== undefined &&
            <UserHappines badExperienceLastOrder={this.state.userHappiness.lastOrder}
              goodExperiences={this.state.userHappiness.previosOrders.cant}
              badExperiences={this.state.userHappiness.previosOrders.cant_mala_experiencia}
              cliente={this.state.orderSelected.cliente} />
          }

          <ol className='DrawerDashboardList'>
            <li>
              <h6>{this.state.orderSelected.cliente}</h6>
              <div className='RowCenter'>
                <b>{this.state.orderSelected.cliente_telefono}</b>
                <div>
                  <button onClick={() => { this.openInZoho(this.state.orderSelected.cliente_email) }} >
                    <FontAwesomeIcon icon={faHeadset} />
                  </button>
                </div>
              </div>
            </li>
            <li>
              <h6>{this.state.orderSelected.proveedor}</h6>
              <div className='RowCenter'>
                <b>{this.state.orderSelected.petshop_telefono}</b>
                <div>
                  <button onClick={() => { this.openInZoho(this.state.orderSelected.petshop_email) }} >
                    <FontAwesomeIcon icon={faHeadset} />
                  </button>
                </div>
              </div>
            </li>
            <li>
              <h6>Entrega el {this.state.orderSelected.fechaEntrega}</h6>
              <div className='RowCenter'>
                <b>¿Reprogramar?</b>
                <button onClick={() => { this.setState({ showReprogramar: true }) }} >
                  <FontAwesomeIcon icon={faCalendarDay} />
                </button>
              </div>
            </li>
            {this.state.orderSelected.tag_seguimiento ?
              <li>
                <h6>En Seguimiento</h6>
                <div className='RowCenter'>
                  <b>Hay {this.state.orderSelected.tag_seguimiento} nota/s</b>
                  <VerNotas loadAsync={this.loadComments} data={this.state.comments} />
                </div>
              </li>
              : null}
          </ol>
          <button className='CustomOmiButton' onClick={() => { this.props.history.push('/compras?compra_id=' + this.state.orderSelected.id); }}>Ver el pedido</button>
        </div>

      </Drawer>
    )
  }
  refrescarCompra = () => {
    this.hideReprogramar();
  }
  hideReprogramar = () => {
    this.setState({ orderSelected: {}, showReprogramar: false }, () => this.props.reset())
  }



  reprogramarContent = () => {
    return <CompraReprogramar
      refrescarCompra={this.refrescarCompra}
      showReprogramar={this.state.showReprogramar}
      hideReprogramar={this.hideReprogramar}
      orderId={this.state.orderSelected.id}
      compra={this.state.orderSelected} />
  }


  render() {
    const { loading } = this.state;



    var columnsProblema = [
      {
        title: '#Pedido',
        dataIndex: 'id',
        key: 'id',
        defaultSortOrder: 'descend',
        width: 150,

        render: (compra, row, index) => {
          var inconveniente = '';
          var capacidad = '';

          if (row.tag_mala_experiencia > 0) {
            inconveniente = <Tag color='green'>Rendimiento</Tag>
          }
          if (row.tag_capacidad > 0) {
            capacidad = <Tag color='gold'>Capacidad</Tag>
          }
          return <div>{compra} <br /> {inconveniente} {capacidad} </div>
        },
        fixed: 'left',
      },
      {
        title: 'fecha de entrega',
        dataIndex: 'fechaEntrega',
        key: 'fechaEntrega',
        render: (compra, row, index) => {
          var reprogramado = '';
          if (row.tag_reprogramado > 0) {
            reprogramado = <Tag color='red'>Reprogramado</Tag>
          }
          return <div>{compra} <br /> {reprogramado} </div>
        },
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        defaultSortOrder: 'descend',
        render: (estado, row, index) => {
          var deliveryData = '';
          if (row.estado_transporte && row.estado_transporte === "en camino") {
            deliveryData = <Tag color='blue'>En camino</Tag>
          } else if (row.estado_transporte && row.estado_transporte.length > 0) {
            deliveryData = <Tag color='green'>{row.estado_transporte}</Tag>
          }
          return <div>{estado} <br /> {deliveryData} </div>
        },
      },
      {
        title: 'Tipo problema',
        dataIndex: 'tipo_problema',
        //sorter: (a, b) => a.tipo_problema - b.tipo_problema,
        key: 'tipo_problema',
        filters: this.props.filterTypeProblemsForTable,
        filterMultiple: false,
        onFilter: (value, record) => {
          console.log('value', value, 'record', record);
          return record.tipo_problema.indexOf(value) === 0
        },
        sorter: (a, b) => a.tipo_problema.length - b.tipo_problema.length,
        sortDirections: ['descend', 'ascend'],

      },
      {
        title: 'Petshop comentario',
        dataIndex: 'petshop_comentarios',
        key: 'petshop_comentarios',
      },

      {
        title: 'Cliente',
        dataIndex: 'cliente',
        key: 'cliente',
        //...this.getColumnSearchProps('cliente'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Domicilio',
        dataIndex: 'domicilio',
        key: 'domicilio',
        //...this.getColumnSearchProps('domicilio'),
        render: (compra, row, index) => {
          var domicilio = '';
          if (row.tag_cambio_domicilio > 0) {
            domicilio = <Tag color='Tomato'>Cambio domicilio</Tag>
          }
          return <div>{compra} <br />  {domicilio} </div>
        },
      },
      {
        title: 'Petshop',
        dataIndex: 'proveedor',
        key: 'proveedor',
        //...this.getColumnSearchProps('proveedor'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
        width: 400,
        //...this.getColumnSearchProps('producto'),
        defaultSortOrder: 'descend',
        render: (producto, row, index) => {
          var array_producto = producto.split(',');
          return <ul className="listaTablaPedidoItem">{array_producto.map((cada_producto, index) => <li key={index}>{cada_producto}</li>)}</ul>
        },
      },
      {
        title: 'Monto',
        dataIndex: 'monto',
        key: 'monto',
        defaultSortOrder: 'descend',
        render: (text, row, index) => {
          let total = text
          if (row.discount) {
            total = total - row.discount;
          }
          return <span>{formatter.format(total)}</span>
        },
      },
      {
        title: 'Pago',
        dataIndex: 'medio',
        key: 'medio',
        defaultSortOrder: 'descend',
      },
      {
        title: 'Fecha creación',
        dataIndex: 'fechaCreacion',
        key: 'fechaCreacion',
        //...this.getColumnSearchProps('fecha'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Nota',
        dataIndex: 'nota',
        key: 'nota',
      },

    ];




    // Loading
    if (loading) {
      return (
        <LoadingOmar />
      );
    }

    return (
      <section className='TableBox'>
        {this.drawerContet()}
        {this.state.orderSelected.id !== undefined && this.reprogramarContent()}

        {this.state.cantTotal
          ? this.table(this.state.cantTotal, this.state.rows, 'A solucionar', 1, 'alert', 'rojo', columnsProblema)
          : null}
      </section>
    )
  }
}




class Download extends React.Component {
  render() {
    return (
      <ExcelFile>
        <ExcelSheet data={this.props.orders} name="Pedidos">
          {this.props.cols.map((eachCol, iCol) =>
            <ExcelColumn key={iCol} label={eachCol.title} value={eachCol.key} />
          )}
          <ExcelColumn label={'ciudad'} value={'dom_ciudad'} />
          <ExcelColumn label={'localidad'} value={'dom_localidad'} />
          <ExcelColumn label={'barrio'} value={'dom_barrio'} />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default withRouter(TablaPedidos);
export { Download };
import AuthService from './AuthService';

const Auth = new AuthService(process.env.REACT_APP_ZOHO_API_URI);

const Zohohelper = {
    // CONFIG
    getTickets: function (params = false) { return Auth.get(GET_TICKETS, params); },
    //postConfig: function (params) { return Auth.post(POST_CONFIG, params); },
};


//ROUTES API ZOHO DESK
const ZOHO_DESK_ENDPOINT = 'zohodesk_controller/';
const GET_TICKETS = ZOHO_DESK_ENDPOINT + 'tickets/';

export default Zohohelper;
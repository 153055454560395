import Modal from 'react-modal';
import { FiX } from "react-icons/fi";
import './Modal.css';
import Button from './Button';

export default function MegaModal({visible,component,title, onOk, onCancel}) {
  return (
    <Modal
      isOpen={visible}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="RowCenter SpaceBetween ModalHead">
        <h6>{title}</h6>
        <FiX/>
      </div>
      {component}

      <div className="RowCenter SpaceBetween ModalFooter">
        <div/>
        <div className="RowCenter">
          <Button type={'outline-danger'} labelText='Cancelar' onPress={()=>onCancel()}/>
          <Button type={'primary'} labelText='Accionable' onPress={()=>onOk()}/>
        </div>
      </div>
    </Modal>
  )
}


/*

        <div className="RowCenter SpaceBetween ModalHead">
          <h6>Hola, soy un Modal</h6>
          <FiX/>
        </div>
        <div className="ModalBody">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi debitis eveniet laboriosam possimus et dolorem, commodi laborum alias saepe facilis architecto beatae officia vitae, fugit fugiat quae qui cupiditate dolorum magni. Dicta vitae labore eligendi a cum alias facilis culpa animi molestias, nulla reprehenderit laborum ea adipisci ullam obcaecati repellendus nisi eveniet modi! Quos aspernatur consequatur cumque sequi itaque, quibusdam in sapiente voluptates fugiat iusto fugit quae dolorum. Soluta sint culpa ipsum pariatur voluptate? Nam et facilis doloribus, ab iste repudiandae deleniti! Dolorum fugit suscipit eaque sit voluptas, consequatur veritatis est odio! Necessitatibus maxime, excepturi officia ullam incidunt alias voluptatibus? Obcaecati deserunt, at aut explicabo debitis omnis non sapiente. Rem, cupiditate sint? Quo consequuntur cum tempore eaque debitis illo earum ab sequi maxime eos inventore enim reiciendis est repellendus ex ea, sit voluptatibus corrupti minus dolores excepturi quae nobis animi tenetur! Consectetur, voluptatibus, dolores distinctio fugit vero quod dolorem odit nisi autem molestias soluta dolore in atque esse officiis illo a! Numquam labore sint excepturi doloribus cumque unde quis corrupti praesentium odit aspernatur! Numquam, sed laboriosam. Sint sapiente dolore amet eos, in, vel magni eveniet quaerat maxime hic quis dicta odit rerum accusamus recusandae mollitia illo repellat totam soluta earum.
        </div>
        <div className="RowCenter SpaceBetween ModalFooter">
          <div/>
          <div className="RowCenter">
            <Button type={'outline-danger'} labelText='Cancelar'/>
            <Button type={'primary'} labelText='Accionable' />
          </div>
        </div>


*/
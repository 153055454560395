import React, { useEffect } from 'react'
import LoadMapAndDraw from '../../utils/LoadMaps';

// Variables
var centro;
var mymap;
var marker;

function Leaflet({ lat, lng, id }) {
    const zoom = 17;
    const scriptMapAndDraw = LoadMapAndDraw();
    useEffect(() => {
        if (scriptMapAndDraw === "ready") {
            initMap();
        }
        // eslint-disable-next-line
    }, [lat, lng, scriptMapAndDraw])



    const initMap = () => {
        // Leaflet
        const L = window.L;
        // CENTRO
        centro = [lat, lng];

        // MAPA
        mymap = L.map(id).setView(centro, zoom);

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mymap);

        // MARKER
        marker = L.marker(centro);
        marker.addTo(mymap)

    }


    return (
        <div id={id} style={{ width: "100%", height: "100%" }}></div>
    )
}

export default Leaflet;

import React, { Suspense } from "react";
import { pure } from 'recompose';
import { Redirect, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MispichosHoC from "./MispichosHoC";
import "bootstrap/dist/css/bootstrap.min.css";
import 'antd/dist/antd.css';
import './components/dashboard-elements/Reset.css';
import './assets/OliverPlus.css';
import AuthService from "./utils/AuthService";

// service workers
import * as serviceWorker from './serviceWorkerRegistration';
import { Modal } from 'antd';
import Mispichoshelper from "./utils/Mispichoshelper";
import routes from "./routes";
import routesDynamic from "./routesDynamic";
import { /*filterRoutes,*/ filterRoutesMix } from "./routesDynamic";
import LoadingOmar from "./components/OmarComponets/LoadingOmar";

const Auth = new AuthService();

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { estaLogeado: false, searchingNew: false, mostrar: false, showModal: false, modalText: "", desactive: false, myRoutes: [] }
  }

  async componentDidMount() {
    this.processServiceWorkers();
    var logeado = await Auth.loggedIn();

    // Obtener rutas si estas logueado
    if (logeado) {
      await this.getMyRoutes();
    } else {
      this.setState({ estaLogeado: logeado, mostrar: true }, this.postLoggedCheck);
    }
  }

  getMyRoutes = async () => {
    Mispichoshelper.getRoutes().then((response) => {
      console.log("response!!!!", response);
      if (response !== undefined && response.status) {

        let myRoutes = response.payload;
        this.setState({
          myRoutes: myRoutes,
          estaLogeado: true,
          mostrar: true
        }, () => this.postLoggedCheck());
      } else {
        Auth.logout();
        window.location.reload()
        return <Redirect to="/login" />
      }
    }).catch((err) => {
      Auth.logout();
      window.location.reload()
      return <Redirect to="/login" />
    });

  }

  postLoggedCheck = () => {
    if (this.state.estaLogeado && Auth.isProfile('proveedor')) {
      this.createZohoASAP();
      this.checkConsoleOpened();
    }
  }

  createZohoASAP = () => {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    const script = document.createElement("script");
    script.src = "https://desk.zoho.com/portal/api/web/inapp/144130000005520073?orgId=632382603";
    document.body.appendChild(script);
  }

  checkConsoleOpened = () => {
    //dev open check
    var element = new Image();
    var alreadySent = false;
    var devtoolsOpen = false;
    element.__defineGetter__("id", function () {
      devtoolsOpen = true; // This only executes when devtools is open.
    });
    setInterval(function () {
      //console.log(element);
      if (devtoolsOpen && !alreadySent) {
        alreadySent = true;
        Mispichoshelper.Petshop.alertConsoleOpen();
      }
    }, 5000);

    //dev open end check
  }

  registerServiceWorker = () => {
    // Si es correcto, lanzamos una notificación
    console.log('registerService en App.js');
    serviceWorker.register();
  }

  processServiceWorkers() {

    // Comprobamos si el navegador soporta las notificaciones
    if (!("Notification" in window)) {
      alert("Este navegador no es compatible con las notificaciones de escritorio");
    }

    // Comprobamos si los permisos han sido concedidos anteriormente
    else if (Notification.permission === "granted") {
      this.registerServiceWorker();
    }

    // Si no, pedimos permiso para la notificación
    else if (Notification.permission !== 'denied' || Notification.permission === "default") {
      //console.log('preguntar')
      this.setState({ showModal: true, modalText: "Las Notificaciones nos ayudan a mantenerte informado por favor activalas!!!" })
    }

    else if (Notification.permission === 'denied') {
      this.setState({ showModal: true, desactive: true, modalText: "Notamos que tienes las notificaciones desactivadas, nos ayudan a mantenerte informado por favor activalas!!!" })
    }
  }

  askPermission = () => {
    let comp = this;

    // Si viene por msj Desactivado
    if (this.state.desactive) {
      this.setState({ showModal: false, desactive: false, modalText: "" });
      return
    }

    // Si no, pedimos permiso para la notificación
    Notification.requestPermission(function (permission) {
      // Si el usuario nos lo concede, creamos la notificación
      if (permission === "granted") {
        //serviceWorker.register();
      }
      comp.setState({ showModal: false })
    });
  }

  loginUser = () => {
    this.registerServiceWorker()
  }

  render() {


    var routesByRol = routesDynamic.publicRoutes;
    if (!this.state.mostrar) {
      return (<LoadingOmar fullHeight={true} />)
    } else if (this.state.estaLogeado) {
      routesByRol = filterRoutesMix(routesDynamic, routes, this.state.myRoutes);
    }

    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""} >
        <Suspense fallback={<LoadingOmar fullHeight={true} />}>
          <Switch>
            {routesByRol.map((route, index) => {
              return route.path &&
                <PrivateRoute
                  estaLogeado={this.state.estaLogeado}
                  loginUser={this.loginUser}
                  key={index}
                  path={route.path}
                  publicRoute={route.public}
                  exact={route.exact}
                  component={MispichosHoC((props) => {
                    return (
                      <route.layout>
                        <route.component loginUser={this.loginUser} />
                      </route.layout>
                    );
                  })}
                />
            }
            )}

            <Route component={() => { return (<NoMatch />) }} />
          </Switch>
          <ServiceWorkerPermission showModal={this.state.showModal} askPermission={this.askPermission} modalText={this.state.modalText} />
        </Suspense>

      </Router>)

  }
}

const ServiceWorkerPermission = ({ showModal, askPermission, modalText }) => (
  <Modal
    title="Importante!"
    visible={showModal}
    onOk={() => { askPermission() }}
    okText="OK"
    cancelButtonProps={{ style: { display: 'none' } }}
  >
    <p>{modalText}</p>
  </Modal>
)

const NoMatch = () => (<Redirect to="/login?nomatch=true" />)
const PrivateRoute = pure(({ component: Component, publicRoute, loginUser, estaLogeado, ...rest }) => (
  (publicRoute === true || (publicRoute !== true && estaLogeado === true))
    ? <Route {...rest} render={(props) => (<Component  {...props} />)} />
    : <Redirect to="/login" />
))

export default (App);

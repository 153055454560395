import React from "react";
import { Tabs, Modal, Button, Icon } from 'antd';
import { Container, FormCheckbox, FormRadio } from "shards-react";
import Mispichoshelper from "../../utils/Mispichoshelper";

const TabPane = Tabs.TabPane;
const confirm = Modal.confirm;

class CompraReprogramar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { horarioElegido: 0, sinFiltros: false, fechaElegida: 0, visible: true, horarios: [], loading: false, todosLosPetshops: false, petshopElegido: this.props.compra.compra_petshop };
  }

  setPetShopHorarios = () => {
    this.setState({ todosLosPetshops: !this.state.todosLosPetshops }, () => {
      this.setState({ petshopElegido: (this.state.todosLosPetshops ? false : this.props.compra.compra_petshop) });
      this.buscaHorarios();
    });
  }

  setSinFiltros = () => {
    this.setState({ sinFiltros: !this.state.sinFiltros }, () => {
      this.buscaHorarios();
    });
  }

  componentWillReceiveProps(next_props) {
    if (next_props.compra.compra_petshop !== this.state.petshopElegido) {
      this.setState({ petshopElegido: next_props.compra.compra_petshop }, () => {
        this.buscaHorarios();
      });
    }

    //this.buscaHorarios();
  }

  componentDidMount() {
    this.buscaHorarios();
  }

  buscaHorarios() {
    var orderId = this.props.orderId ? this.props.orderId : this.props.compra.compra_id;
    Mispichoshelper.getHorariosDeReprogramacion({
      solo_proveedor_compra: !this.state.todosLosPetshops,
      sin_filtros: this.state.sinFiltros ? 1 : 0,
      compra_id: orderId
    }).then(this.guardaHorarios);
  }

  guardaHorarios = (data) => {
    this.setState({ horarios: data.payload });
  }

  setHorarioElegido = (evt) => {
    var splitted = evt.target.value.split(',');
    this.setState({ horarioElegido: splitted[0] });
    this.setState({ fechaElegida: splitted[1] });
  }


  informaResponse = (response) => {
    var orderId = this.props.orderId ? this.props.orderId : this.props.compra.compra_id;
    this.props.refrescarCompra(orderId);
    this.setState({ loading: false });
    if (response.payload.status) {
      this.success(response);
    } else {
      this.error(response);
    }
  }

  success(response) {
    Modal.success({
      title: response.payload.title,
      content: response.payload.message,
      onOk: this.props.hideReprogramar,
      okText: 'Entendido'
    });
  }

  error(response) {
    Modal.error({
      title: response.payload.title,
      content: response.payload.message,
      onOk: this.props.hideReprogramar,
    });
  }

  reprogramarCompra = () => {
    var orderId = this.props.orderId ? this.props.orderId : this.props.compra.compra_id;
    confirm({
      title: 'Confirma la reprogramación del pedido?',
      content: 'Pero claro que seee!',
      cancelText: 'Volver',
      okText: 'Si, reprogramar',
      onOk: () => {
        this.setState({ loading: true });
        Mispichoshelper.postReprogramaCompra({
          solo_proveedor_compra: !this.state.todosLosPetshops,
          compra_id: orderId, tim_id: this.state.horarioElegido, fecha: this.state.fechaElegida
        })
          .then(this.informaResponse);
      }
    });
  }

  muestraHorarios() {
    if (this.state.loading) { // Omar 9-05-19 Agrego animacion de Loading dependiendo del state.loading
      return <Icon type="loading" className='loader mb-4' />
    } else {
      return (
        <Tabs defaultActiveKey="1">
          {this.state.horarios.map((cada_fecha, index) =>
            <TabPane tab={cada_fecha.nombre.toUpperCase() + ' ' + cada_fecha.numero_dia} key={index + 1}>
              {cada_fecha.horarios.map((cada_horario, i) =>
                <FormRadio
                  key={i}
                  name='Horario'
                  value={cada_horario.tim_id + ',' + cada_fecha.fecha}
                  onChange={evt => this.setHorarioElegido(evt)}  >
                  De {cada_horario.tim_inicio} {cada_horario.tim_fin} hs
                  </FormRadio>
              )}
            </TabPane>
          )}
        </Tabs>
      );
    }
  }

  render() {
    const loading = this.state.loading;
    return (
      <Modal
        title="Reprograma el Horario"
        visible={this.props.showReprogramar}
        onOk={this.reprogramarCompra}
        onCancel={this.props.hideReprogramar}
        footer={[
          <Button key="back" onClick={this.props.hideReprogramar}>
            Volver
                </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={() => this.reprogramarCompra()}>
            Reprogramar
                </Button>,
        ]}
      >
        <Container>
          <h4>Actual:{this.state.petshopElegido ? this.state.petshopElegido : 'Todos los petshops'}</h4>
          {this.muestraHorarios()}
          <br></br>
          <FormCheckbox value='1' onClick={this.setPetShopHorarios}>Ver todos los Pet Shop disponibles</FormCheckbox>
          <FormCheckbox value='2' onClick={this.setSinFiltros}>Ver sin filtro (de capacidad y de inicio franja, se responsable)</FormCheckbox>
        </Container>
      </Modal>
    )
  }
}
export default CompraReprogramar;
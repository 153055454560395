import React from 'react'
import Button from '../components/OmarComponets/Button'
import MegaModal from '../components/OmarComponets/MegaModal'
import Overlay from '../components/OmarComponets/Overlay';
import Tag, { TagList } from '../components/OmarComponets/TagList'

function PruebasOmar() {
  const [modalIsOpen, setIsOpenModal] = React.useState(false);
  const [OverlayIsOpen, setIsOpenOverlay] = React.useState(false);

  return (
    <section>

      <br /><br /><br />

      <div className="">
        <div className="Card">
          <Button type={'primary'} labelText='Abrir Overlay' onPress={() => setIsOpenOverlay(!OverlayIsOpen)} />
          <Overlay OverlayIsOpen={OverlayIsOpen} />
        </div>
        <div className="Card">
          <Button type={'primary'} labelText='Abrir modal' onPress={() => setIsOpenModal(!modalIsOpen)} />
          <MegaModal modalIsOpen={modalIsOpen} />
        </div>
      </div>

      <br /><br /><br />
      <div className="Card">
        <div className="RowCenter">
          <Button type={''} labelText='Default' />
          <Button type={'outline-danger'} labelText='outline-danger' />
          <Button type={'outline-warning'} labelText='outline-warning' />
          <Button type={'outline-primary'} labelText='outline-primary' />
          <Button type={'outline-success'} labelText='outline-success' />
          <Button type={'danger'} labelText='danger' />
          <Button type={'warning'} labelText='warning' />
          <Button type={'primary'} labelText='primary' />
          <Button type={'success'} labelText='success' />
        </div>
        <br />
        <div className="RowCenter">
          <Button type={''} labelText='Default' loading={true} />
          <Button type={'outline-danger'} labelText='outline-danger' loading={true} />
          <Button type={'outline-warning'} labelText='outline-warning' loading={true} />
          <Button type={'outline-primary'} labelText='outline-primary' loading={true} />
          <Button type={'outline-success'} labelText='outline-success' loading={true} />
          <Button type={'danger'} labelText='danger' loading={true} />
          <Button type={'warning'} labelText='warning' loading={true} />
          <Button type={'primary'} labelText='primary' loading={true} />
          <Button type={'success'} labelText='success' loading={true} />
        </div>
      </div>

      <br /><br /><br />
      <div className='Card RowCenter'>
        <Tag type={''} labelText='Default' />
        <br />
        <Tag type={'outline-danger'} labelText='outline-danger' />
        <br />
        <Tag type={'outline-warning'} labelText='outline-warning' />
        <br />
        <Tag type={'outline-primary'} labelText='outline-primary' />
        <br />
        <Tag type={'outline-success'} labelText='outline-success' />
        <br />
        <Tag type={'danger'} labelText='danger' />
        <br />
        <Tag type={'warning'} labelText='warning' />
        <br />
        <Tag type={'primary'} labelText='primary' />
        <br />
        <Tag type={'success'} labelText='success' />
      </div>
      <br /><br /><br />
      <TagList />
      <br /><br /><br />
      <br /><br /><br />
      <br /><br /><br />
      <br /><br /><br />
    </section>
  );
}

export default (PruebasOmar)

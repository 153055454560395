import React from "react";
import { withRouter } from "react-router-dom";
import Mispichoshelper from "../../utils/Mispichoshelper";
import { Collapse, Input, Icon } from 'antd';

const { Panel } = Collapse;


class ComprasAnteriores extends React.Component {

    constructor(props) {
        super(props);
        this.state = { compras_anteriores: [] };
    }

    componentDidMount() {
        Mispichoshelper.getComprasPerfil({ usuario_id: this.props.compra.cliente_id }).then((response) => {
            this.setState({ compras_anteriores: response.payload.rows });
        });
    }

    redireccionaHistorial = (cada_comprita) => {
        this.props.history.push('/compras?compra_id=' + cada_comprita);
        this.props.history.go()
    }

    render() {
        const selectBefore = (
            <select defaultValue="Todos" style={{ width: 150 }}>
                <option value="Todos">Todos</option>
                <option value="Cancelados">Entregados</option>
                <option value="Entregados">Cancelados</option>
            </select>
        );

        const customPanelStyle = {
            background: '#fafafa',
            borderRadius: 4,
            marginBottom: 10,
            border: 0,
            overflow: 'hidden',
        };

        const entregado = (eachOrder) => {
            console.log('cad comprimita', eachOrder)
            var icon = eachOrder.tag_mala_experiencia ? 'close-circle' : 'check-circle';
            var color = eachOrder.tag_mala_experiencia ? '#e00d1f' : '#52c41a';
            return (

                <Icon type={icon} theme="twoTone" twoToneColor={color} onClick={(event) => { event.stopPropagation(); }} />
            )
        };

        return (
            <div>
                <div style={{ marginBottom: 16 }}>
                    <Input addonBefore={selectBefore} placeholder="Busca aca lo que necesites" />
                </div>
                <Collapse bordered={false} className='w-100'>
                    {this.state.compras_anteriores.map((cada_comprita, index) =>
                        <Panel header={"#" + cada_comprita.id + " Entrega " + cada_comprita.proveedor + " el " + cada_comprita.fechaEntrega + "hs | " + cada_comprita.medio + " $" + cada_comprita.monto} key={index} extra={entregado(cada_comprita)} style={customPanelStyle}>
                            {cada_comprita.producto}
                            <br />
                            <p className="textosLink" onClick={() => this.redireccionaHistorial(cada_comprita.id)}>Ir a ese Pedido</p>
                        </Panel>
                    )}
                </Collapse>
            </div>
        );
    }
}

export default withRouter(ComprasAnteriores);
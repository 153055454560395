import { Table } from 'antd'
import React from 'react'

export function RankingPorductTable({ products }) {

    const columns = [
        {
            title: 'Marca',
            dataIndex: 'mar_nombre',
            key: 'mar_nombre'
        },
        {
            title: 'Producto',
            dataIndex: 'producto',
            key: 'producto',
            render: (value, row) => <div>{row.pro_nombre} de {row.var_cantidad}{row.var_ume} </div>
        },
        {
            title: 'Cantidad Vendida',
            dataIndex: 'cantidad',
            key: 'cantidad'
        }
    ]

    return (
        <Table
            className='Card'
            dataSource={products}
            columns={columns}
            pagination={{ position: 'none', pageSize: products.length }}
        />
    )
}

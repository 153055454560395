import React from "react";
import { Icon, Steps, Button } from 'antd';
import { TreeSelect } from 'antd';

const Step = Steps.Step;

const SHOW_PARENT = TreeSelect.SHOW_PARENT;

const treeData = [{
    title: 'Delegado',
    value: 'delega compra',
    key: 'delega compra',
},
{
    title: 'Delegado eliminado',
    value: 'delega compra eliminado',
    key: 'delega compra eliminado',
},
{
    title: 'Cambio de estado',
    value: 'estado compra',
    key: 'estado compra',
},
{
    title: 'Reprogramado',
    value: 'reprograma compra',
    key: 'reprograma compra',
},
{
    title: 'Edicion de Producto',
    value: 'edita compra',
    key: 'edita compra',
},
{
    title: 'Intervención',
    value: 'intervenciones',
    key: 'intervenciones',
},
{
    title: 'Cancelación',
    value: 'cancela compra',
    key: 'cancela compra',
},

];

class CompraMovimientos extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        this.state = { resultadosFiltrados: this.props.resultados, showDigitales: false, imgDigitales: null };
    }

    onChange = (value) => {
        this.setState({ value }, () => {
            this.setState({ resultadosFiltrados: this.props.resultados }, this.filtraResultados.bind(this));
        });
    }

    filtraResultados() {
        var filteredItems = this.state.value.length <= 0 ? this.props.resultados : [];
        this.state.resultadosFiltrados.map((cada_resultado) => {
            this.state.value.map(cada_filtro => {
                if (cada_resultado.tipo === cada_filtro) {
                    filteredItems.push(cada_resultado);
                }
                return filteredItems
            })
            return filteredItems
        })
        this.setState({ resultadosFiltrados: filteredItems });
    }

    // Modal Digitales
    showDigitales(img) {
        console.log("entraaaa a funcion");
        this.setState({
            showDigitales: !this.state.showDigitales,
            imgDigitales: img
        }, () => {
            console.log("sale a funcion", this.state);
        })

    }

    closeDigitales() { this.setState({ showDigitales: false }) }

    render() {
        const tProps = {
            treeData,
            value: this.state.value,
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            searchPlaceholder: 'Agrega los filtros que necesites :D',
            style: {
                width: 300,
            },
        };

        if (this.state.showDigitales && this.state.imgDigitales) {
            return (
                <div style={{ textAlign: "center" }}>
                    <Button style={{ width: "20%", marginBottom: 20 }} onClick={() => { this.closeDigitales() }}>Volver</Button>
                    <img alt={this.state.imgDigitales} style={{ width: "100%", height: "100%" }} src={this.state.imgDigitales} />
                </div>
            )
        }

        return (
            <div>
                <TreeSelect {...tProps} className='w-100' />
                <br></br>
                <br></br>
                <br></br>
                <Steps direction="vertical">
                    {this.state.resultadosFiltrados.map((item, i) => {
                        let titulo = item.titulo === "carga digital" ? "Adjunta digital" : item.titulo;
                        let descripcion = item.titulo === "carga digital" ? <div>{item.fecha} | <button style={{ border: 'none', background: 'none' }} onClick={() => { this.showDigitales(`${item.url_digital}${(item.content.split('/'))[1].trim()}`) }} >Ver digital</button></div> : item.fecha + ' | ' + item.content;
                        return <Step key={i} icon={<Icon type={item.titulo === "admin" ? "user" : "code"} />} title={titulo} description={descripcion} />
                    })}
                </Steps>
            </div>
        );
    }
}
export default CompraMovimientos;
